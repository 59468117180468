<template>
  <template v-if="loggedIn && twofactor">
    <div class="left-sidenav">
      <side-bar :authUser="user" v-if="user" />
    </div>

    <div class="page-wrapper">
      <top-bar :authUser="user" />

      <div class="page-content">
        <!-- <transition name="fade-scale" mode="out-in">
          <router-view :authUser="user" v-if="user"></router-view>
        </transition> -->

        <!-- start vuerouter new change -->
        <router-view v-slot="{ Component }" :authUser="user" v-if="user">
          <transition name="fade-scale" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
        <!-- end vuerouter new change -->
        <footer-app :companyColor="companyColor"></footer-app>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";

const module = "auth";
const companyColorModule = "companycolors";

export default defineComponent({
  name: "AdminLayout",

  setup(props) {
    const store = useStore();

    let loggedIn = ref(false);
    let twofactor = ref(false);
    let user = ref({});

    // load company oncreated
    fetchActiveCompanyColor();

    // fetch record from store
    async function fetchActiveCompanyColor() {
      await store.dispatch(`${companyColorModule}/fetchActiveCompanyColor`);
    }

    /* onMounted(() => {
                document.body.classList = "account-body accountbg dark-sidenav";
                if (this.$auth.check) {
                    document.body.classList = "account-body accountbg dark-sidenav";
                } else {
                    document.body.classList = "account-body accountbg";
                }
            }); */

    loggedIn = computed(() => {
      return store.getters[`${module}/loggedIn`];
    });

    twofactor = computed(() => {
      return store.getters[`${module}/twofactor`];
    });

    user = computed(() => {
      return store.getters[`${module}/user`];
    });

    return {
      user,
      twofactor,
      loggedIn,
      companyColor: store.getters[`${companyColorModule}/companycolor`],
    };
  },
});
</script>
