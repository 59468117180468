import { createWebHistory, createRouter } from "vue-router"

import Base from '../components/Common/Base.vue';

import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import store from './../store/index';
import registrationModuleRoutes from "../components/Admin/Registration/registrationModuleRoutes";
import financeModuleRoutes from "../components/Admin/Finance/financeModuleRoutes";
import treasuryModuleRoutes from "../components/Admin/Treasury/treasuryModuleRoutes";
import messagingModuleRoutes from "../components/Admin/Messaging/messagingModuleRoutes";
import administrationModuleRoutes from "../components/Admin/Administration/administrationModuleRoutes";
import depositLoanModuleRoutes from "../components/Admin/DepositLoan/depositLoanModuleRoutes";
import clientCustomerModuleRoutes from "../components/Customer/clientCustomerModuleRoutes";

const routes = [

    {
        name: 'homes',
        path: '/',
        component: Base,
        meta: {
            auth: false,
            title: 'Home',
            // breadCrumb: 'Home',
            // permission: []
        },
        children: [

            {
                name: 'homeLogin',
                path: '',
                component: () => import ('../components/Frontend/Auth/Login'),
                meta: {
                    auth: false,
                    title: 'Home',
                    breadCrumb: 'Home',
                    layout: 'AuthLayout',
                    // permission: []
                }
            },

            /*
            {
                name: 'clientCustomerProfile',
                path: '/my-profile',
                component: () => import ('../components/Frontend/CustomerProfile'),
                meta: {
                    auth: false,
                    title: 'My Profile',
                    layout: 'CustomerLayout',
                    // permission: []
                }
            },
            */

            {
                name: 'about',
                path: '/about-us',
                component: () => import ('../components/Frontend/About'),
                meta: {
                    auth: false,
                    title: 'About Us',
                    layout: 'CustomerLayout',
                }
            },

            {
                name: 'contact',
                path: '/contact-us',
                component: () =>
                    import ('../components/Frontend/Contact'),
                meta: {
                    auth: false,
                    title: 'Contact Us',
                    layout: 'CustomerLayout',
                }
            },

            {
                name: 'requestdemo',
                path: '/request-demo',
                component: () =>
                    import ('../components/Frontend/RequestDemo'),
                meta: {
                    auth: false,
                    title: 'Request For Demo',
                    layout: 'CustomerLayout',
                }
            },

        ]
    },

    /* {
        name: 'home',
        path: '/',
        component: () =>
            import ('../components/Frontend/Home'),
        meta: {
            auth: false,
            title: 'Home',
            layout: 'CustomerLayout',
        }
    }, */

    /* {
        name: 'about',
        path: '/about-us',
        component: () =>
            import ('../components/Frontend/About'),
        meta: {
            auth: false,
            title: 'About SNB',
            layout: 'CustomerLayout',
        }
    }, */

    /* {
        name: 'contact',
        path: '/contact-us',
        component: () =>
            import ('../components/Frontend/Contact'),
        meta: {
            auth: false,
            title: 'Contact Us',
            layout: 'CustomerLayout',
        }
    }, */

    {
        name: 'login',
        path: '/auth/login',
        component: () =>
            import ('../components/Frontend/Auth/Login'),
        meta: {
            auth: false,
            title: 'Login',
            layout: 'AuthLayout',
            // permission: []
        }
    },

    {
        name: 'forgotpassword',
        path: '/auth/forgot-password',
        component: () =>
            import ('../components/Frontend/Auth/ForgotPassword'),
        meta: {
            auth: false,
            title: 'Forgot Password',
            layout: 'AuthLayout',
            // permission: []
        }
    },

    {
        name: '2fa',
        path: '/auth/login-verification',
        component: () =>
            import ('../components/Frontend/Auth/TwoFactor'),
        meta: {
            auth: true,
            title: 'Login Verification',
            layout: 'TwoFactorLayout',
            permission: []
        }
    },

    {
        name: 'register',
        path: '/auth/register',
        component: () =>
            import ('../components/Frontend/Auth/Register'),
        meta: {
            auth: false,
            title: 'Register',
            layout: 'AuthLayout',
            permission: []
        }
    },

    {
        name: 'onboard',
        path: '/onboard',
        component: () =>
            import ('../components/Frontend/Onboard'),
        meta: {
            auth: false,
            title: 'Onboard'
        }
    },

    {
        name: 'userProfile',
        path: '/profile',
        component: () =>
            import ('../components/Admin/Profile'),
        meta: {
            auth: true,
            title: 'Account Profile'
        }
    },

    {
        name: 'clientCustomerHomes',
        path: '/customer',
        component: Base,
        meta: {
            auth: true,
            title: 'Dashboard',
            breadcrumb: 'Dashboard',
            permission: ['view customer']
        },
        children: clientCustomerModuleRoutes
    },

    {
        name: 'adminHomes',
        path: '/admin',
        component: Base,
        meta: {
            auth: true,
            title: 'Dashboard',
            breadCrumb: 'Admin Home',
            permission: []
        },
        children: [
            {
                name: 'adminHome',
                path: '',
                component: () =>
                    import ('../components/Admin/AdminDashboard'),
                meta: {
                    auth: true,
                    title: 'Dashboard',
                    breadCrumb: 'Admin Home',
                    permission: []
                }
            },

            /* {
                name: 'adminSettings',
                path: 'settings',
                component: () =>
                    import ('../components/Admin/Settings'),
                meta: {
                    auth: true,
                    title: 'Settings',
                    breadCrumb: 'Admin Settings'
                }
            }, */

            {
                name: 'adminActivity',
                path: 'activity',
                component: () =>
                    import ('../components/Admin/Activity'),
                meta: {
                    auth: true,
                    title: 'Activity',
                    breadCrumb: 'Admin Settings'
                }
            },

            {
                path: 'registration',
                name: 'registrationHomes',
                component: Base,
                meta: {
                    auth: true,
                    title: 'Registration',
                    breadcrumb: 'Registration'
                },
                children: registrationModuleRoutes

            },

            {
                path: 'finance',
                name: 'financeHomes',
                component: Base,
                meta: {
                    auth: true,
                    title: 'Finance',
                    breadcrumb: 'Finance'
                },

                children: financeModuleRoutes

            },

            {
                path: 'treasury',
                name: 'treasuryHomes',
                component: Base,
                meta: {
                    auth: true,
                    title: 'Treasury',
                    breadcrumb: 'Treasury'
                },

                children: treasuryModuleRoutes

            },

            {
                path: 'messaging',
                name: 'messagingHomes',
                component: Base,
                meta: {
                    auth: true,
                    title: 'Messaging',
                    breadcrumb: 'Messaging'
                },

                children: messagingModuleRoutes

            },

            {
                path: 'administration',
                name: 'administrationHomes',
                component: Base,
                meta: {
                    auth: true,
                    title: 'Administration',
                    breadcrumb: 'Administration'
                },

                children: administrationModuleRoutes

            },

            {
                path: 'deposits-loans',
                name: 'depositLoanHomes',
                component: Base,
                meta: {
                    auth: true,
                    title: 'Deposits & Loans',
                    breadcrumb: 'Deposits & Loans'
                },

                children: depositLoanModuleRoutes

            },

        ]

    },

    {
        name: "404",
        path: "/:pathMatch(.*)*",
        component: () =>
            import ("../components/Common/NotFound.vue"),
        meta: {
            auth: true,
            title: "Page Not Found",
        }
    }
];

const router = createRouter({
    history: createWebHistory(`/`),
    linkExactActiveClass: "active",
    routes
});


router.beforeEach((to, from) => {

    const routeData = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.title);

    if (routeData) {
        document.title = `${routeData.meta.title} - SNB`;
    }

    // check if the route requires auth
    if (routeData.meta.auth) {

        /* let mainPath = to.path.substring(1).split('/');
        let rootPath = mainPath[0]; */

        // check if the user is authenticated
        if (isAuthenticated()) {

            // if no 2fa is in storage redirect and path being accessed is not 2fa route

            if ((!sessionStorage.getItem('2fa') && (to.path != '/auth/login-verification'))) {
                window.location = '/auth/login-verification';
                // router.replace({ name: '2fa'});
            }

            // get logged user data
            const loggedUser = store.getters["auth/user"];
            let userEmail = loggedUser.email;
            // console.log("loggedUser", loggedUser);

            /* if ((rootPath == 'admin') && (loggedUser.customer)) {
                next({name: 'clientCustomerHome'});
                return;
            } */

            // check if the user is a superadmin and allow for full authorization
            // or has necessary permissions
            if (userEmail === 'superadministrator@snb.co.ke' || hasPermissionsNeeded(loggedUser, to))  {
                //next();
                return true;
            } else {
                //next(false);
                return false;
            }

            // next();
            // return;

        } else {

            //redirect to login
            //next({ name: 'homeLogin'});
            // next({ name: 'login'});
            return '/';

        }

    } else {

        /* if (to.path == '/') {
            // console.log("in home login routerpath");
            localStorage.setItem('vuex', null);
            localStorage.clear();
        } */

        //else proceed
        //next();
        return true;

    }

});

function isTwoFactor() {
    const twofactor = sessionStorage.getItem('2fa');
    // console.log("twofactor ", twofactor);
    return twofactor!=undefined ? true : false;
}

function isAuthenticated() {
    const token = sessionStorage.getItem('access_token');
    return token ? true : false;
}

function hasPermissionsNeeded(loggedUser, to) {
    const routePermissions = to.meta.permission;
    if (routePermissions && routePermissions.length > 0) {
        const userPermissions = loggedUser.employee ? loggedUser.employee.permissions : loggedUser.customer.permissions;
        // console.log("router js userPermissions === ", userPermissions);
        return userPermissions.some(userperms => routePermissions.includes(userperms));
    }
    return true;
}

export default router
