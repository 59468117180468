<template>
  <!-- <v-date-picker
            class="inline-block h-full"
            v-model="date"
            :mode="mode"
            :attributes='attrs' hourSetting ref="theDate"
            @dayclick="onDayClick">
        <template v-slot="{ inputValue, inputEvents, togglePopover }">
            <div class="flex items-center">
                <button
                class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                @click="togglePopover()"
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="w-4 h-4 fill-current"
                >
                    <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                    ></path>
                </svg>
                </button>
                <input
                    id="date"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="text-right form-control bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                    readonly
                />
            </div>
        </template>
    </v-date-picker> -->

  <v-date-picker
    v-model="date"
    :min-date="minDate"
    :max-date="maxDate"
    :mode="mode"
    :masks="{ input: ['DD/MM/YYYY'] }"
    :model-config="modelConfig"
    :attributes="attrs"
    hourSetting
    ref="theDate"
    @dayclick="onDayClick"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 form-control"
        :class="styles"
        :title="title"
        :value="inputValue"
        :disabled="isDisabled"
        v-on="inputEvents"
      />
    </template>
  </v-date-picker>
</template>

<style src="../../../node_modules/v-calendar/dist/style.css"></style>

<script>
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "MyDatePicker",

  props: {
    mode: {
      type: String,
      default: "dateTime",
    },
    hourSetting: {
      default: "is12hr",
    },
    textAlign: {
      default: "left",
    },
    styles: {
      default: "",
    },
    isDisabled: {
      default: false,
    },
    dateChangeHandler: {
      type: Function,
      default: () => {},
    },
    minDate: {
      default: "",
    },
    maxDate: {
      default: "",
    },
    title: {
      default: "",
    },
    modelValue: {
      // type: Date,
      // default: new Date(),
      default: "",
    },
  },

  emits: ["update:modelValue", "onchange"],

  setup(props, { emit }) {
    const mode = ref(props.mode);
    const hourSetting = ref(props.hourSetting);
    let theDate = ref(null);
    const date = ref(props.modelValue);
    const minDate = ref(props.minDate);
    const maxDate = ref(props.maxDate);
    const textAlign = ref(props.textAlign);
    const isDisabled = ref(props.isDisabled);

    // watch for props value changes and update as necessary
    watch(
      () => props.modelValue,
      (value) => {
        date.value = value;
      }
    );

    watch(
      () => props.isDisabled,
      (value) => {
        isDisabled.value = value;
      }
    );

    function onDayClick() {
      emit("update:modelValue", date.value);
      emit("onchange", date.value);
    }

    return {
      date,
      timezone: "",
      attrs: [
        /* {
                key: 'today', 
                highlight: true,
                dates: new Date(),
                }, */
      ],
      // mode,
      // hourSetting,
      theDate,
      minDate,
      maxDate,
      // textAlign,
      // isDisabled,
      onDayClick,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD HH:mm:ss",
      },
    };
  },
});
</script>
