import axios from "axios";

export default {

    namespaced: true,

    state: {

        customers: [],
        customer: {}

    },

    getters: {

        customers(state) {
            return state.customers;
        },
        customer(state) {
            return state.customer;
        }

    },

    mutations: {

        SET_CUSTOMERS(state, customers) {
            return state.customers = customers
        },

        SET_CUSTOMER(state, customer) {
            return state.customer = customer
        },

        SET_NEXT_OF_KIN(state, nextofkin) {
            return state.customer.next_of_kins.push(nextofkin);
        }

    },

    actions: {

        async fetchCustomers({ commit }, params) {

            await axios.get('/api/v1/customers', { params: params })
                .then(res => {
                    commit('SET_CUSTOMERS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchCustomersLess({ commit }, params) {

            await axios.get('/api/v1/customers/less', { params: params })
                .then(res => {
                    commit('SET_CUSTOMERS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async filterCustomers({ commit }, params) {

            await axios.get('/api/v1/customers/filter', { params: params })
                .then(res => {
                    commit('SET_CUSTOMERS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async addNextOfKin({ commit }, nextofkin) {

            commit('SET_NEXT_OF_KIN', nextofkin);

        },

        async fetchCustomer({ commit }, customer_id) {

            await axios.get(`/api/v1/customers/${customer_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_CUSTOMER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createCustomer({ commit, dispatch }, customer) {

            await axios.post('/api/v1/customers', customer)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchCustomers');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateCustomer({ commit, dispatch }, customer) {

            console.log("customer ", customer);
            await axios("/api/v1/customers/" + customer.customer_id, {
                method: "patch",
                data: customer,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchCustomers');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteCustomer({ commit }, dispatch, customer_id) {

            await axios.delete(`/api/v1/customers/${customer_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchCustomers');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        },

        async activateMember({ commit, dispatch }, user) {

            await axios.post('/api/v1/customers/activate', user)
                .then(res => {

            }).catch(err => {
                return Promise.reject(err);
            })

        },

    }


}
