import Base from '../../../Common/Base.vue';
import leadsRoutes from './Leads/leadsRoutes';

const membershipStaggingRoutes = [

    {

        name: 'membershipStaggingHome',
        path: '',
        component: () => import('./ListMembershipStagging.vue'),
        meta: {
            auth: true,
            title: 'Membership Stagging Listing',
            breadcrumb: 'Membership Stagging Listing',
            permission: ['view member']
        }

    },

    {
        path: ':id',
        name: 'showMembershipStagging',
        component: () => import('./ShowMembershipStagging.vue'),
        meta: {
            auth: true,
            title: 'Showing Membership Stagging',
            breadcrumb: 'Showing Membership Stagging',
            permission: ['view member']
        }
    },

    {
        path: ':id/edit',
        name: 'editMembershipStagging',
        component: () => import('./EditMembershipStagging.vue'),
        meta: {
            auth: true,
            title: 'Edit Membership Stagging',
            breadcrumb: 'Edit Membership Stagging',
            permission: ['update member']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteStaggedMember',
        meta: {
            auth: true,
            title: 'Delete Stagged Member',
            permission: ['delete member']
        }
    },

    {

        path: 'leads',
        name: 'leadsHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Leads',
            breadcrumb: 'Leads',
            permission: ['view member', 'create member', 'update member', 'delete member']
        },

        children: leadsRoutes

    },

];

export default membershipStaggingRoutes;
