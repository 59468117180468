const cashManagementFinanceRoutes = [

    {

        path: '',
        name: 'cashManagementHome',
        component: () => import('./HomeCashManagement.vue'),
        meta: {
            auth: true,
            title: 'Cash Management',
            breadcrumb: 'Cash Management',
            permission: []
        },

    },

    {
        path: 'loan-repayment',
        name: 'loanrepayment',
        component: () => import('./LoanRepayment.vue'),
        meta: {
            auth: true,
            title: 'Loan Repayment',
            breadcrumb: 'Loan Repayment',
            permission: []
        },
    },

    {
        path: 'loan-adjustment',
        name: 'loanadjustment',
        component: () => import('./LoanAdjustment.vue'),
        meta: {
            auth: true,
            title: 'Loan Adjustment',
            breadcrumb: 'Loan Adjustment',
            permission: []
        },
    },

    {
        path: 'shares-purchase',
        name: 'sharespurchase',
        component: () => import('./SharesPurchase.vue'),
        meta: {
            auth: true,
            title: 'Shares Purchase',
            breadcrumb: 'Shares Purchase',
            permission: []
        },
    },

    {
        path: 'loan-rebate',
        name: 'rebate',
        component: () => import('./Rebate.vue'),
        meta: {
            auth: true,
            title: 'Rebate',
            breadcrumb: 'Rebate',
            permission: []
        },
    },

    {
        path: 'loan-waiver',
        name: 'waiver',
        component: () => import('./Waiver.vue'),
        meta: {
            auth: true,
            title: 'Waiver',
            breadcrumb: 'Waiver',
            permission: []
        },
    },

    {
        path: 'loan-restructure',
        name: 'restructure',
        component: () => import('./Restructure.vue'),
        meta: {
            auth: true,
            title: 'Restructure',
            breadcrumb: 'Restructure',
            permission: []
        },
    },

    {
        path: 'writeoff',
        name: 'writeoff',
        component: () => import('./Writeoff.vue'),
        meta: {
            auth: true,
            title: 'Write Off',
            breadcrumb: 'Write Off',
            permission: []
        },
    },
];

export default cashManagementFinanceRoutes;
