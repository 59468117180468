const leadsRoutes = [

    {

        name: 'leadsHome',
        path: '',
        component: () => import('./ListLeads.vue'),
        meta: {
            auth: true,
            title: 'Leads Listing',
            breadcrumb: 'Leads Listing',
            permission: ['view member']
        }

    },

    {
        path: ':id',
        name: 'showLeads',
        component: () => import('./ShowLeads.vue'),
        meta: {
            auth: true,
            title: 'Showing Leads',
            breadcrumb: 'Showing Leads',
            permission: ['view member']
        }
    },

    {
        path: ':id/edit',
        name: 'editLeads',
        component: () => import('./EditLeads.vue'),
        meta: {
            auth: true,
            title: 'Edit Leads',
            breadcrumb: 'Edit Leads',
            permission: ['update member']
        }
    },
    {
        path: 'delete/:id',
        name: 'deleteLead',
        meta: {
            auth: true,
            title: 'Delete Lead',
            permission: ['delete member'] 
        }
    },

];

export default leadsRoutes;
 