import axios from "axios";

export default {

    namespaced: true,

    state: {

        userstagings: [],
        userstaging: {}

    },

    getters: {

        userstagings(state) {
            return state.userstagings;
        },
        userstaging(state) {
            return state.userstaging;
        }

    },

    mutations: {

        SET_STAGED_USERS(state, userstagings) {
            return state.userstagings = userstagings
        },

        SET_STAGED_USER(state, userstaging) {
            return state.userstaging = userstaging
        }

    },

    actions: {

        async fetchUserStagings({ commit }, params) {

            await axios.get('/api/v1/users-staging', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_STAGED_USERS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchUserStaging({ commit }, user_staging_id) {

            await axios.get(`/api/v1/users-staging/${user_staging_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_STAGED_USER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createUserStaging({ commit, dispatch }, userstaging) {

            await axios.post('/api/v1/users-staging', userstaging)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchUserStagings');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateUserStaging({ commit, dispatch }, userstaging) {

            await axios("/api/v1/users-staging/" + userstaging.user_staging_id, {
                method: "patch",
                data: userstaging,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchUserStagings');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteUserStaging({ commit }, user_staging_id) {

            await axios.delete(`/api/v1/users-staging/${user_staging_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchUserStagings');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
