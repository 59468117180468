const customerEmployeeAllocationRegistrationRoutes = [

    {

        name: 'customerEmployeeAllocationHome',
        path: '',
        component: () => import('./ListCustomerEmployeeAllocations.vue'),
        meta: {
            auth: true,
            title: 'Staff Allocations',
            breadcrumb: 'Staff Allocations',
            permission: ['view employeeallocation']
        }

    },



];

export default customerEmployeeAllocationRegistrationRoutes;
