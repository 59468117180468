<template>
  <div ref="mapContainer" class="leaflet-container" id="map-canvas"></div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, nextTick } from "vue";
import L from "leaflet";

export default {
  props: {
    latlng: {
      type: Object,
      default: null,
    },
    zoom: {
      type: Number,
      default: 13,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    streetAddress: String,
  },
  setup(props, { emit }) {
    const map = ref(null);
    const marker = ref(null);
    const mapContainer = ref(null);

    // initialize the map on mount
    onMounted(() => {
      // check if props.latlng is defined and contains valid values
      // default values
      const defaultLatlng = [-1.284569235418726, 36.888678073883064];
      const centerLatlng = props.latlng || defaultLatlng;

      console.log("centerLatlng === ", centerLatlng);

      map.value = L.map(mapContainer.value, {
        center: centerLatlng,
        zoom: props.zoom,
        dragging: (props.isEdit && !props.isShow) || props.isNew,
        scrollWheelZoom: (props.isEdit && !props.isShow) || props.isNew,
        zoomControl: (props.isEdit && !props.isShow) || props.isNew,
      });

      // add tile layer
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
      }).addTo(map.value);

      // create marker if latlng is provided
      if (props.latlng) {
        createMarker(props.latlng);
      }

      // enable marker addition on map click
      if ((props.isEdit && !props.isShow) || props.isNew) {
        map.value.on("click", (event) => {
          const latlng = event.latlng;
          if (marker.value) {
            marker.value.setLatLng(latlng);
            emit("changedLocation", { lat: latlng.lat, lng: latlng.lng });
          } else {
            createMarker(latlng);
            emit("changedLocation", { lat: latlng.lat, lng: latlng.lng });
          }
          map.value.panTo(latlng);
        });
      }
    });

    // remove map on unmount
    onUnmounted(() => {
      if (map.value) {
        map.value.remove();
      }
    });

    // watch for changes in props.latlng and update marker position
    watch(
      () => props.latlng,
      (newLatlng) => {
        // check if map.value is defined before calling panTo
        if (map.value && newLatlng !== null) {
          if (marker.value) {
            marker.value.setLatLng(newLatlng);
          } else {
            createMarker(newLatlng);
          }
          map.value.panTo(newLatlng);
        }
      }
    );

    // create marker with given latlng
    const createMarker = (latlng) => {
      const icon = L.icon({
        iconUrl: "/path/to/icon.png",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
      });

      // check if map.value is defined before adding the marker
      if (map.value) {
        marker.value = L.marker(latlng, {
          icon: icon,
          draggable: (props.isEdit && !props.isShow) || props.isNew,
        });

        // Check if marker.value is defined before adding it to the map
        if (marker.value) {
          marker.value.addTo(map.value);

          if (props.isShow) {
            marker.value.bindPopup(props.streetAddress).openPopup();
          }

          // set up watcher for streetAddress prop
          watch(
            () => props.streetAddress,
            (newStreetAddress) => {
              if (marker.value && props.isShow) {
                marker.value.setPopupContent(newStreetAddress);
              }
            }
          );
        }
      }
    };

    /* const createMarker = (latlng) => {
      const icon = L.icon({
        iconUrl: "/path/to/icon.png",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
      });

      marker.value = L.marker(latlng, {
        icon: icon,
        draggable: (props.isEdit && !props.isShow) || props.isNew,
      }).addTo(map.value);

      if (props.isShow) {
        marker.value.bindPopup(props.streetAddress).openPopup();
      }

      // set up watcher for streetAddress prop
      watch(
        () => props.streetAddress,
        (newStreetAddress) => {
          if (marker.value && props.isShow) {
            marker.value.setPopupContent(newStreetAddress);
          }
        }
      );
    }; */

    return {
      mapContainer,
    };
  },
};
</script>

<style scoped>
#map-canvas {
  height: 400px;
  width: 100%;
  background: #fcfcfc;
}
</style>
