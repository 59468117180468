const employeeRegistrationRoutes = [

    {

        name: 'employeeHome',
        path: '',
        component: () => import('./ListEmployees.vue'),
        meta: {
            auth: true,
            title: 'Staff Lists',
            breadcrumb: 'Staff Lists',
            permission: ['view employee']
        }

    },

    {
        path: 'create',
        name: 'createEmployee',
        component: () => import('./CreateEmployee.vue'),
        meta: {
            auth: true,
            title: 'Create Staff',
            breadcrumb: 'Create Staff',
            permission: ['create employee']
        }
    },

    {
        path: ':id',
        name: 'showEmployee',
        component: () => import('./ShowEmployee.vue'),
        meta: {
            auth: true,
            title: 'Showing Staff',
            breadcrumb: 'Showing Staff',
            permission: ['view employee']
        }
    },

    {
        path: ':id/edit',
        name: 'editEmployee',
        component: () => import('./EditEmployee.vue'),
        meta: {
            auth: true,
            title: 'Edit Staff',
            breadcrumb: 'Edit Staff',
            permission: ['update employee']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteEmployee',
        meta: {
            auth: true,
            title: 'Delete Staff',
            permission: ['delete employee']
        }
    },

];

export default employeeRegistrationRoutes;
