const mpesaSettingRegistrationRoutes = [

    {

        name: 'mpesaSettingHome',
        path: '',
        component: () => import('./ListMpesaSettings.vue'),
        meta: {
            auth: true,
            title: 'Mpesa Settings Listing',
            breadcrumb: 'Mpesa Settings Listing',
            permission: ['view mpesasetting']
        }

    },

    {
        path: 'create',
        name: 'createMpesaSetting',
        component: () => import('./CreateMpesaSetting.vue'),
        meta: {
            auth: true,
            title: 'Create MpesaSetting',
            breadcrumb: 'Create MpesaSetting',
            permission: ['create mpesasetting']
        }
    },

    {
        path: ':id',
        name: 'showMpesaSetting',
        component: () => import('./ShowMpesaSetting.vue'),
        meta: {
            auth: true,
            title: 'Showing MpesaSetting',
            breadcrumb: 'Showing MpesaSetting',
            permission: ['view mpesasetting']
        }
    },

    {
        path: ':id/edit',
        name: 'editMpesaSetting',
        component: () => import('./EditMpesaSetting.vue'),
        meta: {
            auth: true,
            title: 'Edit MpesaSetting',
            breadcrumb: 'Edit MpesaSetting',
            permission: ['update mpesasetting']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteMpesaSetting',
        meta: {
            auth: true,
            title: 'Delete MpesaSetting',
            permission: ['delete mpesasetting']
        }
    }

];

export default mpesaSettingRegistrationRoutes;
