<template>
  <template class="container" v-if="!loggedIn && !twofactor">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-6 align-self-center">
        <div class="card">
          <!-- <transition name="fade" mode="out-in">
            <router-view />
          </transition> -->

          <auth-header 
                  :page-title="'Enter details below to continue'" 
                  :companyColor="companyColor" v-if="companyColor">
          </auth-header>

          <!-- start vuerouter new change -->
          <router-view
            v-slot="{ Component }"
            :companyColor="companyColor"
            v-if="companyColor"
          >
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
          <!-- end vuerouter new change -->
          <footer-auth :companyColor="companyColor"></footer-auth>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";

const module = "auth";
const companyColorModule = "companycolors";

export default defineComponent({
  name: "AuthLayout",

  /* props: {
    pageTitle: {
      type: String,
      default: "",
    },
  }, */

  setup(props) {

    // console.log("pageTitle authLayout ==== ", props.pageTitle);

    const store = useStore();

    let loggedIn = ref(false);
    let twofactor = ref(false);
    // const companyColor = ref({});

    loggedIn = computed(() => {
      return store.getters[`${module}/loggedIn`];
    });

    twofactor = computed(() => {
      return store.getters[`${module}/twofactor`];
    });

    // load company oncreated
    fetchActiveCompanyColor();

    // fetch record from store
    async function fetchActiveCompanyColor() {
      await store.dispatch(`${companyColorModule}/fetchActiveCompanyColor`);
    }

    /* companyColor.value = computed(() => {
      return store.getters[`${companyColorModule}/companycolor`];
    }); */

    return {
      twofactor,
      loggedIn,
      companyColor: store.getters[`${companyColorModule}/companycolor`],
    };
  },
});
</script>
