// import axios from "axios";
import AuthService from './../../../services/AuthService';

// const user = JSON.parse(sessionStorage.getItem('user'));
const user = JSON.parse(sessionStorage.getItem('user'));

const initialState = user
  ? { status: { loggedIn: true, twofactor: false }, user }
  : { status: { loggedIn: false, twofactor: false }, user: null };

export default {

    namespaced: true,
    state: initialState,

    getters: {

        user(state) {
            return state.user;
        },
        twofactor(state) {
            return state.status.twofactor;
        },
        loggedIn(state) {
            return state.status.loggedIn;
        }

    },

    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        twoFactorSuccess(state, user) {
            state.status.twofactor = true;
            state.user = user;
        },
        twoFactorFailure(state) {
            state.status.twofactor = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.status.twofactor = false;
            state.user = null;
            localStorage.clear();
            localStorage.setItem('vuex', "null");
            sessionStorage.clear();
            window.location = '/';
            // window.location = '/auth/login';
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
            state.status.twofactor = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
            state.status.twofactor = false;
        }
    },

    actions: {

        login({ commit }, data) {
            return AuthService.login(data).then(
              res => {
                commit('loginSuccess', res.data.user);
                return Promise.resolve(res.data.user);
              },
              error => {
                commit('loginFailure');
                return Promise.reject(error);
              }
            );
        },

        twoFactor({ commit }, data) {
            return AuthService.twoFactor(data).then(
                res => {
                    commit('twoFactorSuccess', res);
                    return Promise.resolve(res);
                })
                .catch(error => {
                    // if token has expired
                    if ((error.status == '419') || (error.status == '401')) {
                        commit('twoFactorFailure');
                    }
                    return Promise.reject(error);
                });
        },

        resendTwoFactor({ commit }) {
            return AuthService.resendTwoFactor().then(
              data => {
                return Promise.resolve(data);
              },
              error => {
                return Promise.reject(error);
              }
            );
        },

        logout({ commit }) {
            return axios.post('/api/v1/auth/logout')
            .then(
                response => {
                    commit('logout');
                },
                error => {
                    commit('logout');
                }
            );
        },

        frontendlogout({ commit }) {
            commit('logout');
        },

        forgotpassword({ commit }, user) {
            return AuthService.forgotpassword(user).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        }

    }

}
