<template>
  <div class="container-fluid no-gutters px-0">
    <div class="row">
      <div class="col-12 mt-3xx mb-2">
        <search-bar
          :input-handler="onTermsChange"
          :create-handler="onCreateClick"
          :show-adv-search="show_adv_search"
          :bread-crumb-only="true"
          :display-new-button="false"
          :display-branches="false"
          :display-statuses="true"
          :display-at-top-level="true"
          :status-section="'maker_checker'"
          :status-handler="onStatusChange"
          @changeAdvSearch="onAdvSearchChange"
        >
        </search-bar>

        <div class="card">
          <div class="card-body">
            <search-bar-listing
              :display-labels="false"
              :display-branches="false"
              :display-statuses="true"
              :display-start-date="true"
              :display-end-date="true"
              :status-section="'maker_checker'"
              :sections="sections"
              :display-maker-checker-sections="true"
              :status-handler="onStatusChange"
              :start-date-handler="onStartDateChange"
              :end-date-handler="onEndDateChange"
              :maker-checker-section-handler="onMakerCheckerSectionChange"
            >
            </search-bar-listing>

            <div class="table-responsive-x loader-div">
              <table
                class="table table-hoverx table-bordered mb-0"
                v-if="makercheckers.data && makercheckers.data.length"
              >
                <thead>
                  <tr>
                    <th width="15%">Section</th>
                    <th width="15%">Action</th>
                    <th width="15%">Module</th>
                    <th width="15%">Created By</th>
                    <th width="15%">Status</th>
                    <th width="15%">Created At</th>
                    <th width="10%">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in makercheckers.data" :key="index">
                    <td>{{ item.makercheckersection?.section_name }}</td>
                    <td>{{ item.action }}</td>
                    <td>{{ item.module }}</td>
                    <td>{{ item.user?.full_name }}</td>
                    <td>
                      <span :class="getStatusColorCss(item?.status_id)">{{
                        item.status?.status_name
                      }}</span>
                    </td>
                    <td>{{ formatDate(item.created_at) }}</td>

                    <td class="text-left">
                      <router-link
                        class="mr-2"
                        title="View"
                        :to="{
                          name: routeName,
                          params: { id: item.maker_checker_id },
                        }"
                      >
                        <i class="las la-eye text-info font-18"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>

              <records-not-found v-else />
            </div>
          </div>
        </div>

        <hr class="small" />

        <paginate
          :data="makercheckers.pagination"
          :page-count="makercheckers.pagination.last_page"
          :click-handler="onPageChange"
          v-if="makercheckers.pagination"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import useSweetAlert from "@/components/Common/useSweetAlert.js";

import { formatDate, getStatusColorCss } from "@/filters";

const module = "makercheckers";
const maker_checker_sections_module = "makercheckersections";
const statuses_module = "statuses";

export default defineComponent({
  name: "ListMakerCheckers",

  props: {
    sections: {
      type: Array,
      default: () => [""],
    },
    routeName: {
      type: String,
      default: "",
    },
  },

  components: {},

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const showModal = ref(false);
    const show_adv_search = ref(false);
    const page = ref(1);
    const search_terms = ref(null);
    const start_date = ref(null);
    const end_date = ref(null);
    const status_id = ref(null);
    const section = ref(null);
    let payload = ref(null);

    const { showDialog } = useSweetAlert();

    const showApproveDialog = async (id) => {
      const result = await showDialog(
        "Confirm",
        "",
        "question",
        "Yes",
        "No",
        "Are you sure you want to <b>approve</b> item?"
      );
      if (result) {
        console.log("YES", id);
      }
    };

    const showRejectDialog = async (id) => {
      const result = await showDialog(
        "Confirm",
        "",
        "question",
        "Yes",
        "No",
        "Are you sure you want to <b>reject</b> item?"
      );
      if (result) {
        console.log("YES CLICKED", id);
      }
    };

    // load data on create component
    payload = getPayload();
    // get params
    store.dispatch(`${module}/fetchMakerCheckers`, payload);

    // create handler
    function onCreateClick() {
      // show create company component
      router.push({
        name: "createMakerChecker",
      });
    }

    function rejectMakerChecker() {
      console.log("rejectMakerChecker");
    }

    function approveMakerChecker() {
      console.log("approveMakerChecker");
    }

    // on adv Search Change
    function onAdvSearchChange() {
      console.log("Changed");
    }

    function onStatusChange(status) {
      status_id.value = status;
      searchData();
    }

    function onMakerCheckerSectionChange(sectionvalue) {
      section.value = sectionvalue;
      searchData();
    }

    function onStartDateChange(date) {
      start_date.value = date;
      searchData();
    }

    function onEndDateChange(date) {
      end_date.value = date;
      searchData();
    }

    async function deleteMakerChecker(maker_checker_id) {
      // delete item
      await store.dispatch(`${module}/deleteMakerChecker`, maker_checker_id);
    }

    // perform search
    function searchData() {
      page.value = 1;
      payload = getPayload();
      // fetch data with page param
      store.dispatch(`${module}/fetchMakerCheckers`, payload);
    }

    function onPageChange(thisPage) {
      show_adv_search.value = false;
      page.value = thisPage;
      payload = getPayload();
      // fetch data with page param
      store.dispatch(`${module}/fetchMakerCheckers`, payload);
    }

    // get search payload
    function getPayload() {
      let sectionsProp = "";
      if (props.sections) {
        // serialize the sections array into a comma-separated string
        sectionsProp = props.sections.join(",");
      }
      payload = {
        page: page.value,
        start_date: start_date.value,
        end_date: end_date.value,
        section: section.value,
        status_id: status_id.value,
        relations: "1",
        sections: sectionsProp,
      };
      return payload;
    }

    return {
      makercheckers: computed(() => store.getters[`${module}/makercheckers`]),
      search_statuses: computed(() => store.getters[`${statuses_module}/statuses`]),
      maker_checker_sections: computed(
        () => store.getters[`${maker_checker_sections_module}/makercheckersections`]
      ),
      onPageChange,
      showModal,
      page,
      search_terms,
      show_adv_search,
      onCreateClick,
      onAdvSearchChange,
      deleteMakerChecker,
      approveMakerChecker,
      rejectMakerChecker,
      showApproveDialog,
      showRejectDialog,
      onStatusChange,
      onMakerCheckerSectionChange,
      onStartDateChange,
      onEndDateChange,
      formatDate,
      getStatusColorCss,
    };
  },
});

// tippy('.tippy-btn');
/* tippy('#myElement', {
            html: document.querySelector('#feature__html'), // DIRECT ELEMENT option
            arrow: true,
            animation: 'fade'
        }); */
</script>
