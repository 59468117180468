<template>

    <div id="map-canvas">
        <l-map
            v-model="zoom"
            v-model:zoom="zoom"
            :center="latlng"
            @move="log('move')"
            @click="onMapClick"
            @update:zoom="zoomUpdated"
            @update:center="centerUpdated"
            >

            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>

            <l-control-layers />

            <!-- <l-marker :lat-lng="[0, 0]" draggable @moveend="log('moveend')">
                <l-tooltip>
                lol
                </l-tooltip>
            </l-marker> -->

            <l-marker :lat-lng="latlng">

                <!-- <l-icon :icon-url="iconUrl" :icon-size="iconSize" :icon-shadow="iconShadow" :icon-anchor="iconAnchor" :icon-popup="iconPopup" /> -->

                <!-- <l-icon></l-icon> -->

                <l-icon
                    :icon-size="iconSize"
                    :icon-url="iconUrl" >
                </l-icon>

            </l-marker>

            <!-- <l-marker :lat-lng="[50, 50]" draggable @moveend="log('moveend')">
                <l-popup>
                lol
                </l-popup>
            </l-marker> -->

            <!-- <l-polyline
                :lat-lngs="[
                [47.334852, -1.509485],
                [47.342596, -1.328731],
                [47.241487, -1.190568],
                [47.234787, -1.358337],
                ]"
                color="green"
            ></l-polyline>

            <l-polygon
                :lat-lngs="[
                [46.334852, -1.509485],
                [46.342596, -1.328731],
                [46.241487, -1.190568],
                [46.234787, -1.358337],
                ]"
                color="#41b782"
                :fill="true"
                :fillOpacity="0.5"
                fillColor="#41b782"
            />

            <l-rectangle
                :lat-lngs="[
                [46.334852, -1.509485],
                [46.342596, -1.328731],
                [46.241487, -1.190568],
                [46.234787, -1.358337],
                ]"
                :fill="true"
                color="#35495d"
            />

            <l-rectangle
                :bounds="[
                [46.334852, -1.190568],
                [46.241487, -1.090357],
                ]"
            >
                <l-popup>
                    lol
                </l-popup>
            </l-rectangle> -->

        </l-map>

    </div>

</template>

<script>

    import {
        LMap,
        LTileLayer,
        LMarker,
        LControlLayers,
        LTooltip,
        LPopup,
        LIcon,
        /* LPolyline,
        LPolygon,
        LRectangle, */
    } from "@vue-leaflet/vue-leaflet";
    import "leaflet/dist/leaflet.css";

    export default {
        components: {
            LMap,
            LIcon,
            LTileLayer,
            LMarker,
            LControlLayers,
            // LTooltip,
            // LPopup,
            /* LPolyline,
            LPolygon,
            LRectangle, */
        },

        props: {

            latlng: {
                type: Object,
                required: true,
                // default: { lat: "-1.284569235418726000", lng: "36.888678073883064000" }
            },

            streetAddress: {
                type: String,
                required: false,
            },

        },

        data() {
            return {
                zoom: 17,
                iconWidth: 80,
                iconHeight: 66,
                iconAnchorWidth: 18,
                iconAnchorHeight: 65,
                popupAnchorWidth: 1,
                popupAnchorHeight: -64,
            };
        },
        computed: {
            iconUrl() {
                // return `https://placekitten.com/${this.iconWidth}/${this.iconHeight}`;
                return './../../../../images/map/red-icon.png';
                // return 'https://lenox.co.ke/images/map-marker.png';
            },
            shadowUrl() {
                return './../../../../images/map/red-shadow.png';
            },
            iconSize() {
                return [this.iconWidth, this.iconHeight];
            },
            iconAnchor() {
                return [this.iconAnchorWidth, this.iconAnchorHeight];
            },
            popupAnchor() {
                return [this.popupAnchorWidth, this.popupAnchorHeight];
            },
        },
        methods: {
            log(a) {
                console.log(a);
            },
            /* changeIcon() {
                this.iconWidth += 2;
                if (this.iconWidth > this.iconHeight) {
                    this.iconWidth = Math.floor(this.iconHeight / 2);
                }
            }, */
            setupMapIcon() {

                // set up custom icon
                var myIcon = L.icon({

                    // x, y - icon and anchor location guide
                    // x == +ve - move right, -ve - move left
                    // y == +ve - move down, -ve - move up

                    iconUrl: './../../../../images/map/red-icon.png',
                    shadowUrl: './../../../../images/map/red-shadow.png',

                    iconSize:     [80, 66], // size of the icon
                    // shadowSize:   [80, 66], // size of the shadow
                    iconAnchor:   [18, 65], // point of the icon which will correspond to marker's location
                    // shadowAnchor: [24, 22],  // the same for the shadow
                    popupAnchor:  [1, -64] // point from which the popup should open relative to the iconAnchor

                });

                return myIcon;

            },

            zoomUpdated (zoom) {
                this.zoom = zoom;
                console.log(this.markers)
            },

            centerUpdated (center) {
                this.center = center;
            },

            onMapClick(e){

                console.log(e);

                /* lat.value = e.latlng.lat;
                lng.value = e.latlng.lng; */

                // emit("changedLocation", { lat:lat.value, lng:lng.value });

                // clear existing marker
                /* if (theMarker != undefined) {
                    map.removeLayer(theMarker);
                }; */

                // centerize on click
                // map.setView([lat.value,lng.value], zoom, {animate: true});

                // add a marker to show where map was clicked
                // theMarker = L.marker([lat.value,lng.value], {icon: myIcon}).addTo(map);

            },

        },
        mounted() {

        },
    };

</script>

<style scoped>
    #map-canvas {
        height:400px; width:100%; background: #fcfcfc;
    }
</style>
