<template>
  <div class="mb-2x">
    <template v-if="bread_crumb_only">
      <div class="row py-1">
        <div class="col-sm-12 bread breadcrumb-height">
          <breadcrumb />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="rowz py-1 search-boxxz search-flex">
        <div class="flex-item bread-flex-item" v-if="display_breadcrumb">
          <breadcrumb />
        </div>

        <div class="flex-item" v-if="display_search_box">
          <input
            class="form-control input-sm"
            type="text"
            v-model="search_terms"
            @input="onInputChange"
            placeholder="Type to search..."
          />
        </div>

        <!-- start show fields at top level -->
        <div class="flex-item" v-if="display_branches && display_at_top_level">
          <div class="form-group">
            <label>Branch:</label>
            <select
              class="form-control"
              v-model="state.branch_id"
              @change="onBranchChange"
              v-if="search_branches.data"
            >
              <option :value="''">Select Branch</option>
              <option
                v-for="(item, index) in search_branches.data"
                :key="index"
                :selected="index === 0"
                :value="item.branch_id"
              >
                {{ item.branch_name }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex-item" v-if="display_statuses && display_at_top_level">
          <div class="form-group">
            <label>Status:</label>
            <select
              class="form-control"
              v-model="state.status_id"
              @change="onStatusChange"
              v-if="search_statuses.data"
            >
              <option :value="''">Select Status</option>
              <option
                v-for="(item, index) in search_statuses.data"
                :key="index"
                :selected="index === 0"
                :value="item.status_id"
              >
                {{ item.status_name }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex-item" v-if="display_at_top_level">
          <div class="form-group">
            <label class="float-right">Start Date:</label>
            <my-date-picker
              :mode="'date'"
              :styles="'text-right'"
              v-model="state.start_date"
              @onchange="onStartDateChange"
            >
            </my-date-picker>
          </div>
        </div>

        <div class="flex-item" v-if="display_at_top_level">
          <div class="form-group">
            <label class="float-right">End Date:</label>
            <my-date-picker
              :mode="'date'"
              :styles="'text-right'"
              v-model="state.end_date"
              @onchange="onEndDateChange"
            >
            </my-date-picker>
          </div>
        </div>
        <!-- end show fields at top level -->

        <div
          class="flex-item"
          v-if="display_adv_search && !display_at_top_level"
        >
          <label class="search-label" v-if="show_adv_search">
            <a
              title="Close Advanced Search"
              @click="show_adv_search = !show_adv_search"
              class="adv-search-link"
            >
              <i class="fas fa-search-minus mr-2">
                <span class="search-text">Hide</span>
              </i>
            </a>
          </label>
          <label class="search-label" v-if="!show_adv_search">
            <a
              title="Open Advanced Search"
              @click="show_adv_search = !show_adv_search"
              class="adv-search-link"
            >
              <i class="fas fa-search-plus mr-2">
                <span class="search-text">Show</span>
              </i>
            </a>
          </label>
        </div>

        <div
          class="flex-item text-right center-search float-right"
          v-if="display_date"
        >
          <span style="margin-right: 0.5rem">Date:</span>
          <my-date-picker
            :mode="'date'"
            :styles="'text-right'"
          ></my-date-picker>
        </div>

        <div
          class="flex-item text-right float-right"
          v-if="display_download_button"
        >
          <button
            type="button"
            class="btn btn-outline-success btn-sm dropdown-toggle text-center"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="Download Report"
          >
            <i class="fa fa-download mr-2"></i>
            <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="dropdown-menu">
            <!-- <a class="dropdown-item thelink" @click="downloadCsvClick">
                        <i class="mdi mdi-file-excel-outline text-success mr-1 icon-center icon-bigger"></i> Download CSV
                    </a> -->
            <a class="dropdown-item thelink" @click="downloadXlsxClick">
              <i
                class="la la-file-excel-o text-success mr-1 la-2x icon-center icon-bigger"
              ></i>
              Download Excel
            </a>
            <a class="dropdown-item thelink" @click="downloadPdfClick">
              <i
                class="la la-file-pdf-o text-danger mr-1 la-2x icon-center icon-bigger"
              ></i>
              Download PDF
            </a>
            <a class="dropdown-item thelink" @click="printPdfClick">
              <i
                class="la la-file-pdf-o text-danger mr-1 la-2x icon-center icon-bigger"
              ></i>
              Print PDF
            </a>
          </div>
        </div>

        <div
          class="flex-item text-right float-right flex-no-grow"
          v-if="display_new_button"
        >
          <button
            class="btn btn-sm btn-outline-primary waves-effect waves-light"
            @click="onCreateClick"
          >
            <i class="mdi mdi-plus mr-2"></i>New
          </button>
        </div>
      </div>

      <hr class="small" v-if="display_adv_search && !display_at_top_level" />

      <!-- adv search -->
      <transition
        name="search"
        mode="out-in"
        v-if="display_adv_search && !display_at_top_level"
      >
        <div class="row adv-search no-gutters mb-2" v-show="show_adv_search">
          <div class="col-md-2 col-lg-3" v-if="display_staff">
            <div class="form-group">
              <label>Staff:</label>
              <select
                class="form-control"
                v-model="state.employee_id"
                @change="onEmployeeChange"
                v-if="search_employees.data"
              >
                <option :value="''">Select Staff</option>
                <option
                  v-for="(item, index) in search_employees.data"
                  :key="index"
                  :selected="index === 0"
                  :value="item.employee_id"
                >
                  {{ item.full_name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2 col-lg-3" v-if="display_branches">
            <div class="form-group">
              <label>Branch:</label>
              <select
                class="form-control"
                v-model="state.branch_id"
                @change="onBranchChange"
                v-if="search_branches.data"
              >
                <option :value="''">Select Branch</option>
                <option
                  v-for="(item, index) in search_branches.data"
                  :key="index"
                  :selected="index === 0"
                  :value="item.branch_id"
                >
                  {{ item.branch_name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2 col-lg-3" v-if="display_statuses">
            <div class="form-group">
              <label>Status:</label>
              <select
                class="form-control"
                v-model="state.status_id"
                @change="onStatusChange"
                v-if="search_statuses.data"
              >
                <option :value="''">Select Status</option>
                <option
                  v-for="(item, index) in search_statuses.data"
                  :key="index"
                  :selected="index === 0"
                  :value="item.status_id"
                >
                  {{ item.status_name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2 col-lg-3" v-if="display_start_date">
            <div class="form-group">
              <label class="float-right">Start Date:</label>
              <my-date-picker
                :mode="'date'"
                :styles="'text-right'"
                v-model="state.start_date"
                @onchange="onStartDateChange"
              >
              </my-date-picker>
            </div>
          </div>

          <div class="col-md-2 col-lg-3" v-if="display_end_date">
            <div class="form-group">
              <label class="float-right">End Date:</label>
              <my-date-picker
                :mode="'date'"
                :styles="'text-right'"
                v-model="state.end_date"
                @onchange="onEndDateChange"
              >
              </my-date-picker>
            </div>
          </div>
        </div>
      </transition>

      <hr class="small" v-show="show_adv_search && !display_at_top_level" />
    </template>
  </div>
</template>

<script>
import { computed, ref, defineComponent, watch, reactive } from "vue";
import { useStore } from "vuex";
import MyDatePicker from "./MyDatePicker.vue";

const branches_module = "branches";
const employees_module = "employees";
const statuses_module = "statuses";

export default defineComponent({
  components: { MyDatePicker },

  name: "SearchBar",

  props: {
    inputHandler: {
      type: Function,
      default: () => {},
    },
    statusHandler: {
      type: Function,
      default: () => {},
    },
    branchHandler: {
      type: Function,
      default: () => {},
    },
    employeeHandler: {
      type: Function,
      default: () => {},
    },
    createHandler: {
      type: Function,
      default: () => {},
    },
    downloadCsvHandler: {
      type: Function,
      default: () => {},
    },
    downloadXlsxHandler: {
      type: Function,
      default: () => {},
    },
    downloadPdfHandler: {
      type: Function,
      default: () => {},
    },
    printPdfHandler: {
      type: Function,
      default: () => {},
    },
    dateHandler: {
      type: Function,
      default: () => {},
    },
    startDateHandler: {
      type: Function,
      default: () => {},
    },
    endDateHandler: {
      type: Function,
      default: () => {},
    },
    showAdvSearch: {
      type: Boolean,
      default: false,
    },
    displayAdvSearch: {
      type: Boolean,
      default: true,
    },
    displaySearchBox: {
      type: Boolean,
      default: true,
    },
    displayNewButton: {
      type: Boolean,
      default: true,
    },
    displayDownloadButton: {
      type: Boolean,
      default: false,
    },
    displayBreadcrumb: {
      type: Boolean,
      default: true,
    },
    displayStatuses: {
      type: Boolean,
      default: true,
    },
    displayBranches: {
      type: Boolean,
      default: true,
    },
    displayAtTopLevel: {
      type: Boolean,
      default: false,
    },
    statusSection: {
      type: String,
      default: "users",
    },
    displayDate: {
      type: Boolean,
      default: false,
    },
    displayStartEndDate: {
      type: Boolean,
      default: false,
    },

    displayStaff: {
      type: Boolean,
      default: false,
    },
    displayDateMode: {
      type: String,
      default: "dateTime",
    },
    breadCrumbOnly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["termsChange", "changeAdvSearch"],

  setup(props, { emit }) {
    const store = useStore();

    const display_adv_search = ref(props.displayAdvSearch);
    const display_new_button = ref(props.displayNewButton);
    const display_download_button = ref(props.displayDownloadButton);
    const show_adv_search = ref(props.showAdvSearch);
    const display_search_box = ref(props.displaySearchBox);
    const display_start_date = ref(props.displayStartEndDate);
    const display_end_date = ref(props.displayStartEndDate);
    const display_date = ref(props.displayDate);
    const display_date_mode = ref(props.displayDateMode);
    const display_breadcrumb = ref(props.displayBreadcrumb);
    const display_statuses = ref(props.displayStatuses);
    const display_branches = ref(props.displayBranches);
    const display_staff = ref(props.displayStaff);
    const display_at_top_level = ref(props.displayAtTopLevel);
    const bread_crumb_only = ref(props.breadCrumbOnly);
    const status_section = ref(props.statusSection);
    const search_terms = ref(null);
    const start_date = ref(null);
    const end_date = ref(null);
    // const status = ref(null);
    // const branch = ref(null);

    const state = reactive({
      status_id: "",
      branch_id: "",
      employee_id: "",
      start_date: undefined,
      end_date: undefined,
    });

    if (!bread_crumb_only.value) {
      // do we show statuses?
      if (display_statuses.value) {
        // load statuses
        let status_payload = {
          report: "1", // fetch all data
          section: status_section.value, // get statuses in this section only
        };
        store.dispatch(`${statuses_module}/fetchStatuses`, status_payload);
      }

      // do we load branches?
      if (display_branches.value) {
        // load data on create component
        let payload = {
          report: "1", // fetch all data
          relations: "1", // fetch data with relations
        };
        store.dispatch(`${branches_module}/fetchBranches`, payload);
      }

      if (display_staff.value) {
        let payload = { report: "1", relations: "1" };
        store.dispatch(`${employees_module}/fetchEmployees`, payload);
      }
    }

    function onInputChange(event) {
      // pass handler back to parent
      props.inputHandler(event.target.value);
    }

    function onCreateClick() {
      props.createHandler();
    }

    /* function downloadCsvClick() {
      props.downloadCsvHandler();
    } */

    function downloadXlsxClick() {
      props.downloadXlsxHandler();
    }

    function downloadPdfClick() {
      props.downloadPdfHandler();
    }

    function printPdfClick() {
      props.printPdfHandler();
    }

    function onDateChange(date) {
      props.dateHandler(date);
    }

    function onBranchChange() {
      // console.log(state.branch_id);
      props.branchHandler(state.branch_id);
    }

    function onEmployeeChange() {
      // console.log(state.employee_id);
      props.employeeHandler(state.employee_id);
    }

    function onStatusChange() {
      // console.log(state.status_id);
      props.statusHandler(state.status_id);
    }

    function onStartDateChange(date) {
      props.startDateHandler(date);
    }

    function onEndDateChange(date) {
      props.endDateHandler(date);
    }

    watch(
      () => show_adv_search,
      (newVal, oldVal) => {
        emit("changeAdvSearch", newVal);
      }
    );

    return {
      onInputChange,
      show_adv_search,
      display_adv_search,
      display_search_box,
      display_new_button,
      display_download_button,
      display_breadcrumb,
      display_statuses,
      display_branches,
      display_staff,
      display_start_date,
      display_end_date,
      display_date,
      display_date_mode,
      display_at_top_level,
      bread_crumb_only,
      onCreateClick,
      // downloadCsvClick,
      downloadXlsxClick,
      downloadPdfClick,
      printPdfClick,
      onDateChange,
      onStartDateChange,
      onEndDateChange,
      onBranchChange,
      onEmployeeChange,
      onStatusChange,
      search_terms,
      start_date,
      end_date,
      state,
      search_branches: computed(
        () => store.getters[`${branches_module}/branches`]
      ),
      search_employees: computed(
        () => store.getters[`${employees_module}/employees`]
      ),
      search_statuses: computed(
        () => store.getters[`${statuses_module}/statuses`]
      ),
    };
  },
});
</script>

<style scoped>
.adv-search {
  /* background-color:rgba(239,249,253, 0.3); */
  background-color: rgba(37, 168, 224, 0.9) !important;
  background-color: rgba(37, 168, 224, 0.009) !important;
  /* border-color:rgba(37, 168, 224, 0.09) !important; */
  margin: 10px;
  padding: 10px 10px 10px 0;
  /* width: 100%;
        background-clip: padding-box; */
  border: 1px solid #e3ebf6;
  border-radius: 0.25rem;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
  transition: top 0.3s ease-out 0.5s, bottom 0.3s ease-out 0.5s;
}
.adv-search .form-group {
  margin-bottom: 0;
  margin-left: 10px;
}

a.adv-search-link {
  color: #25a8e0 !important;
}

a.adv-search-link:hover {
  cursor: pointer;
}

.breadcrumb-height {
  min-height: 35px;
}

.btn.btn-outline-primary:hover {
  color: #ffffff !important;
  background-color: #25a8e0;
}

.search-boxx {
  transition: all 0.7s ease-in;
}

.search-text {
  font-family: "Inter", sans-serif;
  font-size: inherit;
  font-weight: 400;
  /* color: #656d9a; */
  margin-left: 3px;
}

/* search transitions */
.search-enter-from {
  opacity: 0;
  transform: translateY(-20px);
}

.search-enter-active {
  transition: all 0.5s ease-out;
}

/* .search-enter-active, .fade-leave-active {
        transition: all 0.5s ease-out;
    } */

.search-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.search-leave-active {
  transition: all 0.5s ease-in;
}

.bread {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
