<template>
  <template class="container" v-if="loggedIn && !twofactor && user">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-6 align-self-center">
        <div class="card">
          <!-- <router-view :authUser="user" v-if="user"></router-view> -->

          <auth-header 
                  :page-title="'Enter details below to continue'" 
                  :companyColor="companyColor" v-if="companyColor">
          </auth-header>

          <!-- start vuerouter new change -->
          <router-view v-slot="{ Component }" :authUser="user" v-if="user">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
          <!-- end vuerouter new change -->

          <footer-auth></footer-auth>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";

const module = "auth";
const companyColorModule = "companycolors";

export default defineComponent({
  name: "TwoFactorLayout",

  setup() {
    const store = useStore();

    let loggedIn = ref(false);
    let twofactor = ref(false);
    let user = ref({});

    loggedIn = computed(() => {
      return store.getters[`${module}/loggedIn`];
    });

    twofactor = computed(() => {
      return store.getters[`${module}/twofactor`];
    });

    user = computed(() => {
      return store.getters[`${module}/user`];
    });

    // load company oncreated
    fetchActiveCompanyColor();

    // fetch record from store
    async function fetchActiveCompanyColor() {
      await store.dispatch(`${companyColorModule}/fetchActiveCompanyColor`);
    }

    return {
      user,
      twofactor,
      loggedIn,
      companyColor: store.getters[`${companyColorModule}/companycolor`],
    };
  },
});
</script>
