// import api from './api';
import axios from "axios";

class AuthService {

    login(user) {
        return axios.post('/api/v1/auth/login', {
                username: user.username,
                password: user.password,
                user_type: user.user_type
            })
            .then(res => {
                // console.log(" resppp --- HERERERERE --- ", res.data)
                if (res.data.data.access_token) {
                    sessionStorage.setItem('access_token', res.data.data.access_token);
                    sessionStorage.setItem('user', JSON.stringify(res.data.data.user));
                }
                // return res.data;
                return Promise.resolve(res.data);
            },
            error => {
                // console.log("Error in AuthService", error);
                return Promise.reject(error);
            });
    }

    twoFactor(user) {
        return axios.post('/api/v1/auth/twofactor', {
                code: user.code
            })
            .then(res => {
                sessionStorage.setItem('2fa', true);
                return Promise.resolve(res.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    resendTwoFactor() {
        return axios.post('/api/v1/auth/resend-twofactor')
        .then(res => {
            return Promise.resolve(res.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
        // return axios.post('/api/v1/auth/resend-twofactor');
    }

    user() {
        return JSON.parse(sessionStorage.getItem('user'));
    }

    logout() {
        return axios.post('/api/v1/auth/logout')
            .then(res => {
                return Promise.resolve(res.data);
            })
            .catch(error => {
                // console.log(error);
                return Promise.reject(error);
            });
    }

    forgotpassword(user) {
        return axios.post('/api/v1/auth/forgotpassword', {
                username: user.username,
            });
    }

    register(user) {
        return axios.post('/api/v1/auth/register', user);
    }

}

export default new AuthService;

