import axios from "axios";

export default {

    namespaced: true,

    state: {

        users: [],
        user: {}

    },

    getters: {

        users(state) {
            return state.users;
        },
        user(state) {
            return state.user;
        }

    },

    mutations: {

        SET_USERS(state, users) {
            return state.users = users
        },

        SET_USER(state, user) {
            return state.user = user
        }

    },

    actions: {

        async fetchUsers({ commit }, params) {

            await axios.get('/api/v1/users', { params: params })
                .then(res => {
                    // console.log("users == ", res);
                    commit('SET_USERS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchUser({ commit }, user_id) {

            await axios.get(`/api/v1/users/${user_id}`)
                .then(res => {
                    // console.log("fetchUser store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_USER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoggedUser({ commit }) {

            await axios.get(`/api/v1/user`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_USER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createUser({ commit, dispatch }, user) {

            // console.log("user createUser ", user);
            await axios.post('/api/v1/users', user)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchUsers');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateUser({ commit, dispatch }, user) {

            await axios("/api/v1/users/" + user.user_id, {
                method: "patch",
                data: user,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchUsers');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async changeUserPassword({ commit, dispatch }, user) {

            await axios.post('/api/v1/users/changeuserpassword', user)
                .then(res => {
                if (res.statusText === 'OK') {
                    return Promise.resolve(res);
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

      

        async changeSelfPassword({ commit, dispatch }, user) {

            await axios.post('/api/v1/users/changeselfpassword', user)
                .then(res => {
                if (res.statusText === 'OK') {
                    return Promise.resolve(res);
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteUser({ commit }, user_id) {

            await axios.delete(`/api/v1/users/${user_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchUsers');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
