<template>

    <div>

        <!--SUCCESS-->
        <div v-if="isSuccess" class="transition">
            <div class="text-success">Successfully Uploaded</div>
            <p>
                <button type="button" class="btn btn-outline-primary btn-xs waves-effect waves-light" @click="reset()">
                    Upload Again
                </button>
            </p>
        </div>

        <!--FAILED-->
        <div v-if="isFailed" class="transition">
            <div class="text-danger">Upload Failed</div>
            <p>
                <button type="button" class="btn btn-outline-primary btn-xs waves-effect waves-light" @click="reset()">
                    Try Again
                </button>
            </p>
        </div>

        <input type="file" id="files" ref="files" :accept="accept" @change="handleFilesUpload()"/>

        <div class="large-12 medium-12 small-12 cell" v-if="isUploading">

            <div class="progress">
                <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated transition"
                    role="progressbar" style="width:100%" aria-valuenow="100" aria-valuemin="0"
                    aria-valuemax="100"></div>
            </div>

        </div>

        <div class="large-12 medium-12 small-12 cell py-2 transition"  v-if="isUploading || isInitial">
            <div v-for="(file, key) in files" :key="key" class="file-listingz col-sm-12">
                <div style="border:1px; border-color:#f1f1f1; background-color:#f1f1f1; border-radius:5px" class="row py-1 px-1">
                    <div class="col-lg-8 col-sm-12">
                        {{ file.name }}
                    </div>
                    <div class="col-lg-4 col-sm-12 vertical-center" title="Remove item">
                        <span class="remove-file px-2 d-block mx-auto" @click="removeFile(key)">Remove</span>
                        <i class="fa fa-trash remove-file remove-icon fa-lg"></i>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <button type="button" class="btn btn-primary ml-1x transition" @click="addFiles()" :disabled="isUploading || disabled">
                <i class="fa fa-plus" aria-hidden="true"></i> Select File
            </button>

            <button type="button" class="btn btn-success ml-1 transition" @click="submitFiles()" :disabled="isUploading || disabled">
                <i class="fa fa-arrow-up" aria-hidden="true"></i> Start Upload
            </button>
        </div>

    </div>

</template>

<script>

    const STATUS_INITIAL = 0, STATUS_UPLOADING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

    const upload_module = 'fileuploads';

    export default {

        props: {
            section: {type: String, required: true},
            sectionId: {type: Number, required: true},
            uploadType: {type: String, required: true},
            disabled: {type: Boolean, required: false},
            date: {type: String, required: false},
            imageWidth: {type: Number, required: false},
            imageHeight: {type: Number, required: false},
            imageThumbWidth: {type: Number, required: false},
            imageThumbHeight: {type: Number, required: false},
            imageAspectRatio: {type: Number, required: false},
            // uploadItem - can either be image or pdf
            uploadItem: {type: String, required: false, default: "image"},
            uploadPath: {
                type: String,
                default: '/api/v1/uploads',
                required: false
            },
            accept: {
                type: String,
                default: "image/png,image/gif,image/jpeg,image/webp",
                required: false
            },
        },

        data(){
            return {
                files: [],
                uploadedFiles: [],
                uploadError: null,
                currentStatus: null,
                uploadFieldName: 'photos'

            }
        },

        computed: {
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isUploading() {
                return this.currentStatus === STATUS_UPLOADING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            }
        },

        mounted() {
            this.reset();
        },

        methods: {

            reset() {
                // reset form to initial state
                this.currentStatus = STATUS_INITIAL;
                this.uploadedFiles = [];
                this.uploadError = null;
            },

            /* Adds a file */
            addFiles(){
                this.$refs.files.click();
            },

            submitFiles(){
                //clearing the errors if any incase of submit
               store.dispatch(`${upload_module}/setUploadErrors`, []);
                this.currentStatus = STATUS_UPLOADING;

                let formData = new FormData();

                /* Iterate sent files, appending the files to the form data. */
                for( var i = 0; i < this.files.length; i++ ){
                    let file = this.files[i];

                    formData.append('file', file);
                }

                /* Additional POST Data */
                let fullUploadPath = this.uploadPath + '?uploadItem=' + this.uploadItem;
                formData.append('section', this.section);
                formData.append('section_id', this.sectionId);
                formData.append('upload_type', this.uploadType);
                formData.append('date', this.date);
                // check if image attributes have been passed
                if (this.imageWidth != null) {
                    formData.append('width', this.imageWidth);
                }
                if (this.imageHeight != null) {
                    formData.append('height', this.imageHeight);
                }
                if (this.imageThumbWidth != null) {
                    formData.append('thumb_width', this.imageThumbWidth);
                }
                if (this.imageThumbHeight != null) {
                    formData.append('thumb_height', this.imageThumbHeight);
                }
                if (this.imageAspectRatio != null) {
                    formData.append('aspect_ratio', this.imageAspectRatio);
                }
                if (this.imageCaption != null) {
                    formData.append('caption', this.imageCaption);
                }

                axios.post(fullUploadPath,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then(x => {

                    // console.log("x == ", x);

                    this.uploadedFiles = [].concat(x);
                    this.files.pop();
                    // emit uploaded file
                    this.$emit("uploadSuccess", x);
                    this.currentStatus = STATUS_SUCCESS;

                    /* if (x.data?.data != undefined) {
                        // console.log("upload error x in ", x);
                        // set upload errors
                        store.dispatch(`${upload_module}/setUploadErrors`, x.data.data);
                    } else {
                        store.dispatch(`${upload_module}/setUploadErrors`, []);
                    } */

                })
                .catch(err => {

                    // console.log("err == ", err);
                    this.uploadError = err.message;
                    this.currentStatus = STATUS_FAILED;
                    if (err.data?.data != undefined) {
                        // set upload errors
                        store.dispatch(`${upload_module}/setUploadErrors`, err.data.data);
                    } else {
                        store.dispatch(`${upload_module}/setUploadErrors`, []);
                    }

                });
            },

            handleFilesUpload(){
                let uploadedFiles = this.$refs.files.files;

                /* Adds the uploaded file to the files array */
                for( var i = 0; i < uploadedFiles.length; i++ ){
                    this.files.push( uploadedFiles[i] );
                }
            },

            removeFile(key){
                this.files.splice( key, 1 );
            }

        }

    }

</script>

<style>
    input[type="file"]{
        position: absolute;
        top: -500px;
        display: none;
    }

    div.file-listing{
        width: 200px;
    }

    span.remove-file, .remove-file{
        color: red;
        cursor: pointer;
        float: right;
    }

    .remove-icon{
        vertical-align: middle;line-height: 1;
    }
</style>
