<template>
  <div class="menu-content h-100" data-simplebar>
    <div class="row">
      <div class="col-sm-6 logo-bg">
        <div class="brand">
          <router-link
            :to="{ name: 'clientCustomerHome' }"
            class="logo"
            v-if="authUser?.customer"
          >
            <span>
              <img
                v-if="authUser.active_company?.company_logo?.length > 0"
                :src="authUser.active_company?.company_logo[0].thumb_path"
                :alt="authUser?.active_company?.company_name"
                width="70"
                height="70"
                class="logo-sm"
              />

              <img
                v-else
                src="./../../assets/images/logo-sm.png"
                :alt="authUser?.active_company?.company_name"
                width="70"
                height="70"
                class="logo-sm"
              />
            </span>
          </router-link>

          <router-link :to="{ name: 'adminHome' }" class="logo" v-else>
            <span>
              <img
                v-if="authUser.active_company?.company_logo?.length > 0"
                :src="authUser.active_company?.company_logo[0].thumb_path"
                :alt="authUser?.active_company?.company_name"
                width="70"
                height="70"
                class="logo-sm"
              />

              <img
                v-else
                src="./../../assets/images/logo-sm.png"
                :alt="authUser?.active_company?.company_name"
                width="70"
                height="70"
                class="logo-sm"
              />
            </span>
          </router-link>
        </div>
      </div>

      <div class="col-sm-6 logo-text-box center-box">
        <img
          v-if="authUser.active_company?.company_logo_text?.length > 0"
          :src="authUser.active_company?.company_logo_text[0].thumb_path"
          :alt="authUser.active_company.company_name"
          width="86"
          height="34"
          class="logo-lg logo-dark"
        />

        <img
          v-else
          src="./../../assets/images/logo-text.png"
          :alt="authUser.active_company.company_name"
          width="86"
          height="34"
          class="logo-lg logo-dark"
        />

        <!-- <img
                    src="./../../assets/images/logo-text.png"
                    alt="logo-large"
                    class="logo-lg logo-dark"
                /> -->
      </div>
    </div>

    <!-- First level menu -->
    <!-- <ul class="metismenu left-sidenav-menu">

            <template v-for="(item, index) in sidebarnavItems" :key="index">

                {{ item.routeNames }}

                <li v-if="showMenuItem(item.routeNames)">

                    <router-link :to="{ name: item.name }">
                        <i :data-feather="item.icon" class="align-self-center menu-icon"></i>
                        <span>{{ item.title }}</span>
                    </router-link>

                </li> -->

    <!-- Second level menu -->
    <!-- <ul class="nav-second-level" aria-expanded="false" v-if="item.submenu.length > 0">

                    <template v-for="(submenuitem, submenuitemindex) in item.submenu" :key="submenuitemindex">

                        <li v-if="showMenuItem(submenuitem.permission)">

                            <a href="javascript: void(0);">
                                <i class="ti-control-record"></i>{{ submenuitem.title }}
                                <span class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false" v-if="submenuitem.submenu.length > 0">

                                <li>
                                    <router-link :to="{ name: 'accountsHome' }" class="nav-link">
                                    Accounts
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'createJournal' }" class="nav-link">
                                    Journals
                                    </router-link>
                                </li>

                            </ul>

                        </li>

                    </template>

                </ul> -->

    <!-- <hr class="hr-dashed hr-menu" v-if="showMenuItem(item.routeNames)"/>

            </template>

        </ul> -->

    <ul class="metismenu left-sidenav-menu">
      <li v-if="showMenuItem(['adminHome', 'clientCustomerHome']) && authUser.customer">
        <router-link :to="{ name: 'adminHome' }" v-if="authUser?.employee">
          <i data-feather="home" class="align-self-center menu-icon"></i>
          <span>Dashboard</span>
        </router-link>

        <router-link :to="{ name: 'clientCustomerHome' }" v-else>
          <i data-feather="home" class="align-self-center menu-icon"></i>
          <span>Dashboard</span>
        </router-link>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="showMenuItem(['adminHome', 'clientCustomerHomes']) && authUser.customer"
      />

      <li v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer">
        <a href="javascript: void(0);">
          <i data-feather="credit-card" class="align-self-center menu-icon"></i>
          <span>Savings &amp; Deposits</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>

        <ul class="nav-second-level" aria-expanded="false">
          <li>
            <router-link :to="{ name: 'clientCustomerSavingsDepositsDepositsHome' }">
              <a><i class="ti-control-record"></i>My Deposits</a>
            </router-link>
          </li>

          <!-- <li>
                        <router-link :to="{ name: 'clientCustomerSavingsDepositsTransfersHome' }">
                        <a><i class="ti-control-record"></i>My Transfers</a>
                        </router-link>
                    </li> -->

          <li>
            <router-link :to="{ name: 'clientCustomerSavingsDepositsSharesHome' }">
              <a><i class="ti-control-record"></i>My Shares</a>
            </router-link>
          </li>
        </ul>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer"
      />

      <li v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer">
        <router-link :to="{ name: 'clientCustomerLoan' }">
          <i data-feather="calendar" class="align-self-center menu-icon"></i>
          <span>Loans</span>
        </router-link>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer"
      />

      <li v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer">
        <router-link :to="{ name: 'clientCustomerGroup' }">
          <i data-feather="users" class="align-self-center menu-icon"></i>
          <span>Groups</span>
        </router-link>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer"
      />

      <li v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer">
        <router-link :to="{ name: 'clientCustomerRecommend' }">
          <i data-feather="thumbs-up" class="align-self-center menu-icon"></i>
          <span>Recommend Us</span>
        </router-link>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer"
      />

      <li v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer">
        <a href="javascript: void(0);">
          <i data-feather="server" class="align-self-center menu-icon"></i>
          <span>My Account</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>

        <ul class="nav-second-level" aria-expanded="false">
          <li>
            <router-link :to="{ name: 'clientCustomerProfile' }">
              <a><i class="ti-control-record"></i>My Profile</a>
            </router-link>
          </li>

          <!-- <li> -->
          <!-- <router-link :to="{ name: 'smsHome' }"> -->
          <!-- <a @click.prevent="toggleSelfPasswordPopup()" class="custom-link"><i class="ti-control-record"></i>Change PIN</a> -->
          <!-- </router-link> -->
          <!-- </li> -->

          <li>
            <router-link :to="{ name: 'emailHome' }">
              <a><i class="ti-control-record"></i>Additional Info</a>
            </router-link>
          </li>
        </ul>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="showMenuItem(['clientCustomerHomes']) && authUser.customer"
      />

      <li v-if="showMenuItem(['financeHomes']) && authUser.employee">
        <a href="javascript: void(0);">
          <i data-feather="briefcase" class="align-self-center menu-icon"></i>
          <span>Accounts</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>

        <ul class="nav-second-level" aria-expanded="false">
          <li v-if="showMenuItem(['financialYearsHome'])">
            <router-link :to="{ name: 'financialYearsHome' }">
              <a><i class="ti-control-record"></i>Financial Years</a>
            </router-link>
          </li>

          <li v-if="showMenuItem(['accountsHome'])">
            <router-link :to="{ name: 'accountsHome' }">
              <a><i class="ti-control-record"></i>Chart Of Accounts</a>
            </router-link>
          </li>

          <li v-if="showMenuItem(['createJournal'])">
            <router-link :to="{ name: 'createJournal' }">
              <a><i class="ti-control-record"></i>Journals</a>
            </router-link>
          </li>

          <li>
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Payables
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['payableHomes'])">
                <router-link :to="{ name: 'suppliers' }" class="nav-link"
                  >Suppliers
                </router-link>
              </li>

              <li v-if="showMenuItem(['vendorHomes'])">
                <router-link :to="{ name: 'vendorHome' }" class="nav-link"
                  >Vendor Registration
                </router-link>
              </li>

              <li v-if="showMenuItem(['vendorPayments'])">
                <router-link :to="{ name: 'vendorPayments' }" class="nav-link">
                  Payments
                </router-link>
              </li>

              <li v-if="showMenuItem(['otherPayments'])">
                <router-link :to="{ name: 'otherPayments' }" class="nav-link">
                  Other Payments
                </router-link>
              </li>

              <li v-if="showMenuItem(['createPInvoice'])">
                <router-link :to="{ name: 'createPInvoice' }" class="nav-link"
                  >Purchase Invoice
                </router-link>
              </li>

              <li v-if="showMenuItem(['supplierAdjustments'])">
                <router-link :to="{ name: 'supplierAdjustments' }" class="nav-link"
                  >Supplier Adjustments
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="showMenuItem(['receivableHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Receivables
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['customerListings'])">
                <router-link :to="{ name: 'customerListings' }">
                  <a class="nav-link"> Customers </a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['customerReceipts'])">
                <router-link :to="{ name: 'customerReceipts' }">
                  <a class="nav-link"> Receipts </a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['otherReceipts'])">
                <router-link :to="{ name: 'otherReceipts' }">
                  <a class="nav-link"> Other Receipts </a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['createSInvoice'])">
                <router-link :to="{ name: 'createSInvoice' }">
                  <a class="nav-link"> Sales Invoice </a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['customerAdjustments'])">
                <router-link :to="{ name: 'customerAdjustments' }">
                  <a class="nav-link"> Customer Adjustments </a>
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="showMenuItem(['reportHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Reports
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li>
                <router-link :to="{ name: 'ledgerTransactions' }">
                  <a class="nav-link"> Ledger </a>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'journalTransactions' }">
                  <a class="nav-link"> Journal </a>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'trialBalance' }">
                  <a class="nav-link"> Trial Balance </a>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'incomeStatement' }">
                  <a class="nav-link"> Income Statement </a>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'balanceSheet' }">
                  <a class="nav-link">Balance Sheet</a>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="showMenuItem(['financeHomes']) && authUser.employee"
      />

      <li v-if="showMenuItem(['treasuryHomes']) && authUser.employee">
        <a href="javascript: void(0);">
          <i data-feather="layers" class="align-self-center menu-icon"></i>
          <span>Treasury</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>

        <ul class="nav-second-level" aria-expanded="false">
          <li v-if="showMenuItem(['bankingHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Teller Management
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['vaultaccount'])">
                <router-link :to="{ name: 'vaultaccount' }">
                  <a class="nav-link">Vault Account</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['vaultrequest'])">
                <router-link :to="{ name: 'vaultrequest' }">
                  <a class="nav-link">Vault Request</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['branchvault'])">
                <router-link :to="{ name: 'branchvault' }">
                  <a class="nav-link">Branch Vault</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['telleraccount'])">
                <router-link :to="{ name: 'telleraccount' }">
                  <a class="nav-link">Teller Account</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['tellerrequest'])">
                <router-link :to="{ name: 'tellerrequest' }">
                  <a class="nav-link">Teller Requests</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['mytill'])">
                <router-link :to="{ name: 'mytill' }">
                  <a class="nav-link">My Till</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['tellertransactions'])">
                <router-link :to="{ name: 'tellertransactions' }">
                  <a class="nav-link">My Transactions</a>
                </router-link>
              </li>

            </ul>
          </li>

          <li>
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Account Management
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['CashDeposit'])">
                <router-link :to="{ name: 'CashDeposit' }">
                  <span><i class="ti-control-record"></i>Cash Deposit</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['CashWithdrawal'])">
                <router-link :to="{ name: 'CashWithdrawal' }">
                  <span><i class="ti-control-record"></i>Cash Withdraw</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['ChequeDeposit'])">
                <router-link :to="{ name: 'ChequeDeposit' }">
                  <span><i class="ti-control-record"></i>Cheque Deposit</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['ChequeWithdrawal'])">
                <router-link :to="{ name: 'ChequeWithdrawal' }">
                  <span><i class="ti-control-record"></i>Cheque Withdrawal</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'FundTransfer' }">
                  <span><i class="ti-control-record"></i>Fund Transfer</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'Adjustments'}">
                  <span><i class="ti-control-record"></i>Adjustment</span>
                </router-link>
              </li>
              
              <li>
                <router-link :to="{ name: 'MpesaWithdrawal' }">
                  <span><i class="ti-control-record"></i>Mpesa Withdraw</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'MyAccountTransactions' }">
                  <span><i class="ti-control-record"></i>My Transactions</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Shares Management
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li>
                <router-link :to="{ name: 'sharestransfer'}">
                  <span><i class="ti-control-record"></i>Shares Transfer</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'sharespurchase'}">
                  <span><i class="ti-control-record"></i>Shares Purchase</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'sharesredemption'}">
                  <span><i class="ti-control-record"></i>Shares Redemption</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Facility Management
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['loanrepayment'])">
                <router-link :to="{ name: 'loanrepayment' }">
                  <span><i class="ti-control-record"></i>Repayment</span>
                </router-link>
              </li>

              <!-- <li v-if="showMenuItem(['rebate'])">
                <router-link :to="{ name: 'rebate' }">
                  <span><i class="ti-control-record"></i>Rebate</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['waiver'])">
                <router-link :to="{ name: 'waiver' }">
                  <span><i class="ti-control-record"></i>Waiver</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['restructure'])">
                <router-link :to="{ name: 'restructure' }">
                  <span><i class="ti-control-record"></i>Restructure</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['writeoff'])">
                <router-link :to="{ name: 'writeoff' }">
                  <span><i class="ti-control-record"></i>Write-off</span>
                </router-link>
              </li> -->
            </ul>
          </li>


          <li v-if="showMenuItem(['standingOrderHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Standing Order
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['standingorderList'])">
                <router-link :to="{ name: 'standingorderList' }">
                  <span><i class="ti-control-record"></i>Standing Order List</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['failedstandingorderList'])">
                <router-link :to="{ name: 'failedstandingorderList' }">
                  <span><i class="ti-control-record"></i>Failed Standing Order</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Payment Management
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li>
                <router-link :to="{ name: 'MpesaPayment'}">
                  <span><i class="ti-control-record"></i>Mpesa Payment</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'MpesaReconciliation'}">
                  <span><i class="ti-control-record"></i>Reconciliation</span>
                </router-link>
              </li>
            </ul>
          </li>

          

          <li v-if="showMenuItem(['pendingTreasuryHomes'])">
            <router-link :to="{ name: 'pendingTreasuryHome' }">
              <a
                ><i class="ti-control-record"></i>Pending Items
                <!-- <span class="badge badge-danger badge-pill noti-icon-badge">2</span> -->
              </a>
            </router-link>
          </li>
        </ul>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="showMenuItem(['treasuryHomes']) && authUser.employee"
      />

      <li
        v-if="
          showMenuItem([
            'depositHomes',
            'loanApplicationHomes',
            'loanHomes',
            'loanExposureLimitHomes',
            'collateralHomes',
          ]) && authUser.employee
        "
      >
        <a href="javascript: void(0);">
          <i data-feather="grid" class="align-self-center menu-icon"></i>
          <span>Deposits &amp; Financing</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>

        <ul class="nav-second-level" aria-expanded="false">
          <li v-if="showMenuItem(['depositHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Current
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li>
                <router-link :to="{ name: 'depositHome' }">
                  <span><i class="ti-control-record"></i>Current Accounts</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'depositBalance' }">
                  <span><i class="ti-control-record"></i>Balances</span>
                </router-link>
              </li>
            </ul>
          </li>
          
          <!-- <li v-if="showMenuItem(['depositHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Fixed
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li>
                <router-link :to="{ name: 'fixedAccountHome' }">
                  <span><i class="ti-control-record"></i>Fixed Accounts</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'fixedDepositAccountBalance' }">
                  <span><i class="ti-control-record"></i>Balances</span>
                </router-link>
              </li>
            </ul>
          </li> -->

          <li v-if="showMenuItem(['depositHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Shares
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li>
                <router-link :to="{ name: 'shareHome' }">
                  <span><i class="ti-control-record"></i>Share Accounts</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'shareBalance' }">
                  <span><i class="ti-control-record"></i>Share Balances</span>
                </router-link>
              </li>
            </ul>
          </li>

          
          <li
            v-if="
              showMenuItem([
                'loanApplicationHomes',
                'loanHomes',
                'loanExposureLimitHomes',
                'collateralHomes',
              ])
            "
          >
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Financing
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['loanApplicationHomes'])">
                <router-link :to="{ name: 'loanApplicationHome' }">
                  <span><i class="ti-control-record"></i>Applications</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['loanHomes'])">
                <router-link :to="{ name: 'loanHome' }">
                  <span><i class="ti-control-record"></i>Accounts</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['loanExposureLimitHomes'])">
                <router-link :to="{ name: 'loanExposureLimitHome' }">
                  <span><i class="ti-control-record"></i>Exposure Limits</span>
                </router-link>
              </li>

              <!--  v-if="showMenuItem(['collateralHomes'])" -->
              <li>
                <router-link :to="{ name: 'collateralHome' }">
                  <span><i class="ti-control-record"></i>Collateral</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'businesscategory' }">
                  <span><i class="ti-control-record"></i>Business Category</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'listbusinessappraisal' }">
                  <span><i class="ti-control-record"></i>Business Appraisal</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Reports
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">

              <li>
                <router-link :to="{ name: 'defaultedinstallmentreport' }">
                  <span><i class="ti-control-record"></i>Due Installment</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'facilitydisbursementreport' }">
                  <span><i class="ti-control-record"></i>Disbursement</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'defaultersreport' }">
                  <span><i class="ti-control-record"></i>Defaulters</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="
          showMenuItem([
            'depositHomes',
            'loanApplicationHomes',
            'loanHomes',
            'loanExposureLimitHomes',
            'collateralHomes',
          ]) && authUser.employee
        "
      />

      <li v-if="showMenuItem(['smsHomes', 'recommendHomes', 'emailHomes'])">
        <a href="javascript: void(0);">
          <i data-feather="mail" class="align-self-center menu-icon"></i>
          <span>Messaging</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>

        <ul class="nav-second-level" aria-expanded="false">
          <li v-if="showMenuItem(['smsHomes'])">
            <router-link :to="{ name: 'smsHome' }">
              <a><i class="ti-control-record"></i>SMS</a>
            </router-link>
          </li>

          <li v-if="showMenuItem(['emailHomes'])">
            <router-link :to="{ name: 'emailHome' }">
              <a><i class="ti-control-record"></i>Emails</a>
            </router-link>
          </li>

          <li v-if="showMenuItem(['recommendHomes'])">
            <router-link :to="{ name: 'recommendHome' }">
              <a><i class="ti-control-record"></i>User Recommends</a>
            </router-link>
          </li>

          <li v-if="showMenuItem(['pendingMessagingHomes'])">
            <router-link :to="{ name: 'pendingMessagingHome' }">
              <a
                ><i class="ti-control-record"></i>Pending Items
                <!-- <span class="badge badge-danger badge-pill noti-icon-badge">2</span> -->
              </a>
            </router-link>
          </li>
        </ul>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="showMenuItem(['smsHomes', 'recommendHomes', 'emailHomes'])"
      />

      <li
        v-if="
          showMenuItem([
            'groupHomes',
            'groupCategoryHomes',
            'userHomes',
            'branchHomes',
            'customerHomes',
            'employeeHomes',
            'vendorHomes',
            'paybillHomes',
          ])
        "
      >
        <a href="javascript: void(0);">
          <i data-feather="plus-square" class="align-self-center menu-icon"></i>
          <span>Registration &nbsp;</span>
          <!-- <span class="badge badge-danger badge-pill noti-icon-badge">2</span> -->
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>

        <ul class="nav-second-level" aria-expanded="false">
          <li v-if="showMenuItem(['branchHomes'])">
            <router-link :to="{ name: 'branchHome' }">
              <span><i class="ti-control-record"></i>Branch Management</span>
            </router-link>
          </li>

          <li v-if="showMenuItem(['companyHomes'])">
            <router-link :to="{ name: 'companyHome' }">
              <a><i class="ti-control-record"></i>Companies</a>
            </router-link>
          </li>


          <li v-if="showMenuItem(['userHomes'])">
            <router-link :to="{ name: 'userHome' }">
              <span><i class="ti-control-record"></i>Users</span>
            </router-link>
          </li>

          <li
            v-if="
              showMenuItem([
                'customerHomes',
                'groupHomes',
                'groupCategoryHomes',
                'groupAdminHomes',
              ])
            "
          >
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Member Registration
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['customerHomes'])">
                <router-link :to="{ name: 'customerHome' }">
                  <a><i class="ti-control-record"></i>Individual Member</a>
                </router-link>
              </li>

              <li >
                <router-link :to="{ name: 'membershipStaggingHomes' }">
                  <a><i class="ti-control-record"></i>Member Stagging</a>
                </router-link>
              </li>
              <li >
                <router-link :to="{ name: 'leadsHomes' }">
                  <a><i class="ti-control-record"></i>Leads Management</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['groupHomes'])">
                <router-link :to="{ name: 'groupHome' }">
                  <a><i class="ti-control-record"></i>Groups</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['groupCategoryHomes'])">
                <router-link :to="{ name: 'groupCategoryHome' }">
                  <a><i class="ti-control-record"></i>Group Categories</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['groupSubCategoryHomes'])">
                <router-link :to="{ name: 'groupSubCategoryHome' }">
                  <a><i class="ti-control-record"></i>Grp Sub Categories</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['groupRequestHomes'])">
                <router-link :to="{ name: 'groupRequestHome' }">
                  <a><i class="ti-control-record"></i>Group Requests</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['groupAdminHomes'])">
                <router-link :to="{ name: 'groupAdminHome' }">
                  <a><i class="ti-control-record"></i>Group Admins</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['userStagingHomes'])">
                <router-link :to="{ name: 'userStagingHome' }">
                  <a><i class="ti-control-record"></i>Staged Users</a>
                </router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="showMenuItem(['customerEmployeeAllocationHomes', 'customercallHomes'])"
          >
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Member Management
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['customerEmployeeAllocationHomes'])">
                <router-link :to="{ name: 'customerEmployeeAllocationHome' }">
                  <a><i class="ti-control-record"></i>Staff Allocations</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['customercallHomes'])">
                <router-link :to="{ name: 'customercallHome' }">
                  <a><i class="ti-control-record"></i>Members Calls</a>
                </router-link>
              </li>
              <li v-if="showMenuItem(['customercallHomes'])">
                <router-link :to="{ name: 'callreport' }">
                  <a><i class="ti-control-record"></i>Call Report</a>
                </router-link>
              </li>
              <li v-if="showMenuItem(['customercallHomes'])">
                <router-link :to="{ name: 'customerregistrationreport' }">
                  <a><i class="ti-control-record"></i>Registration Report</a>
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="showMenuItem(['employeeHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Staff
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['employeeHomes'])">
                <router-link :to="{ name: 'employeeHome' }">
                  <a><i class="ti-control-record"></i>Staff Management</a>
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="showMenuItem(['pendingRegistrationHomes'])">
            <router-link :to="{ name: 'pendingRegistrationHome' }">
              <a
                ><i class="ti-control-record"></i>Pending Items
                <!-- <span class="badge badge-danger badge-pill noti-icon-badge">2</span> -->
              </a>
            </router-link>
          </li>
        </ul>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="
          showMenuItem([
            'groupHomes',
            'groupCategoryHomes',
            'userHomes',
            'branchHomes',
            'customerHomes',
            'employeeHomes',
            'vendorHomes',
            'paybillHomes',
          ])
        "
      />

      <li
        v-if="
          showMenuItem([
            'configurationHomes',
            'paymentMethodHomes',
            'productSettingHomes',
            'depositSettingHomes',
            'companySettingHomes', 
            'roleHomes',
          ])
        "
      >
        <a href="javascript: void(0);">
          <i data-feather="settings" class="align-self-center menu-icon"></i>
          <span>Administration</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>

        <ul class="nav-second-level" aria-expanded="false">
          <!-- v-if="showMenuItem(['configurationHomes', 'companyHomes')  -->
          <li v-if="showMenuItem(['configurationHomes'])">
            <router-link :to="{ name: 'configurationHome' }">
              <span><i class="ti-control-record"></i>Configuration</span>
            </router-link>
          </li>

          <li v-if="showMenuItem(['dataUploadHomes'])">
            <router-link :to="{ name: 'dataUploadHome' }">
              <span><i class="ti-control-record"></i>Upload Data</span>
            </router-link>
          </li>

          <li v-if="showMenuItem(['paymentMethodHomes'])">
            <router-link :to="{ name: 'paymentMethodHome' }">
              <span><i class="ti-control-record"></i>Payment Methods</span>
            </router-link>
          </li>
          <li>
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Deposits
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li>
                <router-link :to="{ name: 'depositstatusHome' }">
                  <span><i class="ti-control-record"></i>Status</span>
                </router-link>
              </li>

                <li>
                  <router-link :to="{ name: 'depositcontractHome' }">
                    <span><i class="ti-control-record"></i>Contracts</span>
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'depositChargeHome' }">
                    <span><i class="ti-control-record"></i>Charges</span>
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'taxHome' }">
                    <span><i class="ti-control-record"></i>Tax</span>
                  </router-link>
                </li>

                <li v-if="showMenuItem(['depositSettingHomes'])">
                  <router-link :to="{ name: 'depositSettingHome' }">
                    <span><i class="ti-control-record"></i>Deposits</span>
                  </router-link>
                </li>
            </ul>
          </li>
          
          <li>
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Financing
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li>
                <router-link :to="{ name: 'loanstatusHome' }">
                  <span><i class="ti-control-record"></i>Status</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'loanAppraisalHome' }">
                  <span><i class="ti-control-record"></i>Appraisal</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'financingcontractHome' }">
                  <span><i class="ti-control-record"></i>Contracts</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'financingfeeHome' }">
                  <span><i class="ti-control-record"></i>Fees</span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'productcategories' }">
                  <span><i class="ti-control-record"></i>Categories</span>
                </router-link>
              </li>

              <li v-if="showMenuItem(['productSettingHomes'])">
                <router-link :to="{ name: 'productSettingHome' }">
                  <span><i class="ti-control-record"></i>Products</span>
                </router-link>
              </li>

            </ul>
          </li>
          
          <li v-if="showMenuItem(['companySettingHomes'])">
            <router-link :to="{ name: 'companySettingHome' }">
              <span><i class="ti-control-record"></i>Company Settings</span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'registrationFeeSettingHome' }">
              <span><i class="ti-control-record"></i>Registration Settings</span>
            </router-link>
          </li>

    
          <li v-if="showMenuItem(['membershipSettingHomes'])">
              <router-link :to="{ name: 'membershipSettingHome' }">
                <span><i class="ti-control-record"></i>Membership Settings</span>
              </router-link>
            </li> 

          <li v-if="showMenuItem(['roleHomes'])">
            <router-link :to="{ name: 'roleHome' }">
              <a><i class="ti-control-record"></i>Roles</a>
            </router-link>
          </li>

          <li v-if="showMenuItem(['roleAssign'])">
            <router-link :to="{ name: 'roleAssign' }">
              <a><i class="ti-control-record"></i>Assign Role</a>
            </router-link>
          </li>

          <li v-if="showMenuItem(['paybillHomes'])">
            <router-link :to="{ name: 'paybillHome' }">
              <a><i class="ti-control-record"></i>Paybills</a>
            </router-link>
          </li>

          <li v-if="showMenuItem(['registerMpesaPaybill'])">
            <router-link :to="{ name: 'registerMpesaPaybill' }">
              <a><i class="ti-control-record"></i>Register Mpesa Paybill</a>
            </router-link>
          </li>

          <li v-if="showMenuItem(['mpesaSettingHomes'])">
            <router-link :to="{ name: 'mpesaSettingHome' }">
              <a><i class="ti-control-record"></i>Mpesa Settings</a>
            </router-link>
          </li>

          <!-- <li v-if="showMenuItem(['mpesaHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Mpesa Settings
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['mpesab2cHome'])">
                <router-link :to="{ name: 'mpesab2cHome' }">
                  <a><i class="ti-control-record"></i>Mpesa B2C</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['mpesac2bHome'])">
                <router-link :to="{ name: 'mpesac2bHome' }">
                  <a><i class="ti-control-record"></i>MpesaC2B</a>
                </router-link>
              </li>
            </ul>
          </li> -->

          <li v-if="showMenuItem(['smsTemplateHomes', 'emailTemplateHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Message Templates
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['smsTemplateHomes'])">
                <router-link :to="{ name: 'smsTemplateHome' }">
                  <a><i class="ti-control-record"></i>SMS</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['emailTemplateHomes'])">
                <router-link :to="{ name: 'emailTemplateHome' }">
                  <a><i class="ti-control-record"></i>Emails</a>
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="showMenuItem(['smsTemplateMasterHomes', 'emailTemplateMasterHomes'])">
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Message Templates Master
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li v-if="showMenuItem(['smsTemplateMasterHomes'])">
                <router-link :to="{ name: 'smsTemplateMasterHome' }">
                  <a><i class="ti-control-record"></i>SMS</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['emailTemplateMasterHomes'])">
                <router-link :to="{ name: 'emailTemplateMasterHome' }">
                  <a><i class="ti-control-record"></i>Emails</a>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- maker checker -->
          <li
            v-if="
              showMenuItem(['makerCheckerSectionHomes', 'makerCheckerSectionMasterHomes'])
            "
          >
            <a href="javascript: void(0);">
              <i class="ti-control-record"></i>Maker Checker
              <span class="menu-arrow left-has-menu">
                <i class="mdi mdi-chevron-right"></i>
              </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <!-- <li v-if="showMenuItem(['makerCheckerHomes'])">
                <router-link :to="{ name: 'makerCheckerHome' }">
                  <a><i class="ti-control-record"></i>Pending Items</a>
                </router-link>
              </li> -->

              <li v-if="showMenuItem(['makerCheckerSectionHomes'])">
                <router-link :to="{ name: 'makerCheckerSectionHome' }">
                  <a><i class="ti-control-record"></i>Sections</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['makerCheckerSectionUserLevelHomes'])">
                <router-link :to="{ name: 'makerCheckerSectionUserLevelHome' }">
                  <a><i class="ti-control-record"></i>User Levels</a>
                </router-link>
              </li>

              <li v-if="showMenuItem(['makerCheckerSectionMasterHomes'])">
                <router-link :to="{ name: 'makerCheckerSectionMasterHome' }">
                  <a><i class="ti-control-record"></i>Sections Master</a>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- maker checker pending items -->
          <li v-if="showMenuItem(['pendingAdministrationHomes'])">
            <router-link :to="{ name: 'pendingAdministrationHome' }">
              <a
                ><i class="ti-control-record"></i>Pending Items
                <!-- <span class="badge badge-danger badge-pill noti-icon-badge">2</span> -->
              </a>
            </router-link>
          </li>

        </ul>
      </li>

      <hr
        class="hr-dashed hr-menu"
        v-if="
          showMenuItem([
            'configurationHomes',
            'paymentMethodHomes',
            'productSettingHomes',
            'depositSettingHomes',
            'roleHomes',
          ])
        "
      />
    </ul>

    <change-self-password
      :toggle-popup="togglePopup"
      @togglePopup="toggleSelfPasswordPopup"
      v-if="show_change_self_password_modal"
    >
    </change-self-password>
  </div>
</template>

<script>
import * as menuItems from "./MenuItems";
import { mapGetters } from "vuex";

export default {
  props: ["authUser", "companyColor"],
  data() {
    return {
      message: null,
      sidebarnavItems: menuItems.sidebarnavItems,
      show_change_self_password_modal: false,
    };
  },

  methods: {
    // toggleSelfPasswordPopup
    toggleSelfPasswordPopup() {
      this.show_change_self_password_modal = !this.show_change_self_password_modal;
    },

    showMenuItem(routeNamesArray) {
      const userData = this.authUser;

      const userEmail = userData.email;

      // check if superadmin is logged in
      // if so, allow access to all pages
      if (userEmail === "superadministrator@snb.co.ke") {
        return true;
      }

      // if route has permissions
      if (routeNamesArray != null) {
        let permExists = false;
        let routePermissions = [];
        let routeAuth = null;

        // loop through routeNamesArray
        for (let i = 0; i < routeNamesArray.length; i++) {
          let routeName = routeNamesArray[i];

          const resolved = this.$router.resolve({
            name: routeName,
          });

          // get this route permission
          routePermissions = resolved.meta.permission ? resolved.meta.permission : null;
          routeAuth = resolved.meta.auth ? resolved.meta.auth : null;

          // if no permissions exist, exit and allow access
          if (routePermissions.length === 0) {
            return true;
          }

          // check if user has any of the resolved permissions
          // if permission exists in user permissions, return true
          if (routeAuth == true) {
            if (routePermissions != null && routePermissions.length > 0) {
              // get userPermissions
              const userPermissions = userData?.employee
                ? userData?.employee?.permissions
                : userData?.customer?.permissions;
              // console.log("userPermissions === ", userPermissions);

              // check if routePermissions array items contain any of the userPermissions array items
              permExists = userPermissions.some((userperms) =>
                routePermissions.includes(userperms)
              );

              // if first match is found, terminate loop
              if (permExists === true) {
                return permExists;
              }
            }
          }
        }

        return permExists;
      } else {
        return true;
      }
    },
  },

  mounted() {
    feather.replace();

    function t() {
      $(window).width() < 1025
        ? $("body").addClass("enlarge-menu")
        : $("body").removeClass("enlarge-menu");
    }
    $(".metismenu").metisMenu();
    $(window).resize(function () {
      t();
    });
    $(".button-menu-mobile").on("click", function (t) {
      t.preventDefault(), $("body").toggleClass("enlarge-menu");
    });
    t();
    $('[data-toggle="tooltip"]').tooltip();
    $(".main-icon-menu .nav-link").on("click", function (t) {
      $("body").removeClass("enlarge-menu"),
        t.preventDefault(),
        $(this).addClass("active"),
        $(this).siblings().removeClass("active"),
        $(".main-menu-inner").addClass("active");
      var a = $(this).attr("href");
      $(a).addClass("active"), $(a).siblings().removeClass("active");
    });
    $(".leftbar-tab-menu a, .left-sidenav a").each(function () {
      var t = window.location.href.split(/[?#]/)[0];
      if (this.href == t) {
        $(this).addClass("active"),
          $(this).parent().addClass("active"),
          $(this).parent().parent().addClass("in"),
          $(this).parent().parent().addClass("mm-show"),
          $(this).parent().parent().parent().addClass("mm-active"),
          $(this).parent().parent().prev().addClass("active"),
          $(this).parent().parent().parent().addClass("active"),
          $(this).parent().parent().parent().parent().addClass("mm-show"),
          $(this).parent().parent().parent().parent().parent().addClass("mm-active");
        var a = $(this).closest(".main-icon-menu-pane").attr("id");
        $("a[href='#" + a + "']").addClass("active");
      }
    });
    Waves.init();
  },
};
</script>

<style scoped>
.logo-bg {
  background-color: #ffffff;
}
</style>
