import axios from "axios";

export default {

    namespaced: true,

    state: {

        statistics: [],
        statistic: {}

    },

    getters: {

        statistics(state) {
            return state.statistics;
        },
        statistic(state) {
            return state.statistic;
        }

    },

    mutations: {

        SET_STATISTICS(state, statistics) {
            return state.statistics = statistics
        },

        SET_STATISTIC(state, statistic) {
            return state.statistic = statistic
        }

    },

    actions: {

        async fetchStatistics({ commit }, params) {

            await axios.get('/api/v1/statistics', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_STATISTICS', res.data.data);
                    return Promise.resolve(res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchStatistic({ commit }, status_id) {

            await axios.get(`/api/v1/statistics/${status_id}`)
                .then(res => {
                    // console.log("fetchStatistic store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_STATISTIC', res.data.data);
                        return Promise.resolve(res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        }

    }

}
