const roleAdministrationRoutes = [

    {

        name: 'roleHome',
        path: '',
        component: () => import('./ListRoles.vue'),
        meta: {
            auth: true,
            title: 'Roles Listing',
            breadcrumb: 'Roles Listing',
            permission: ['view role']
        }

    },

    {

        name: 'roleAssign',
        path: 'assign',
        component: () => import('./AssignRole.vue'),
        meta: {
            auth: true,
            title: 'Assign User Roles',
            breadcrumb: 'Assign User Roles',
            permission: ['update role']
        }

    },

    {
        name: 'createRole',
        path: 'create',
        component: () => import('./CreateRole.vue'),
        meta: {
            auth: true,
            title: 'Create Role',
            breadcrumb: 'Create Role',
            permission: ['create role']
        }
    },

    {
        name: 'showRole',
        path: ':id',
        component: () => import('./ShowRole.vue'),
        meta: {
            auth: true,
            title: 'Showing Role',
            breadcrumb: 'Showing Role',
            permission: ['view role']
        }
    },

    {
        name: 'editRole',
        path: ':id/edit',
        component: () => import('./EditRole.vue'),
        meta: {
            auth: true,
            title: 'Edit Role',
            breadcrumb: 'Edit Role',
            permission: ['update role']
        }
    },

    {
        name: 'deleteRole',
        path: 'delete/:id',
        meta: {
            auth: true,
            title: 'Delete Role',
            permission: ['delete role']
        }
    },

];

export default roleAdministrationRoutes;
