<template>
  <div>
    <!-- render the component based on section -->
    <!-- makerChecker::: <br />
    <pre>{{ makerChecker }}</pre> -->

    <component
      :is="sectionComponent"
      :isShow="true"
      :makerChecker="makerChecker"
      :isMakerChecker="true"
      v-if="makerChecker"
    />
  </div>
</template>

<script>
import { defineComponent, computed, watch } from "vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import BranchDetail from "@/components/Admin/Registration/Branch/BranchDetail.vue";
import PaybillDetail from "@/components/Admin/Registration/Paybill/PaybillDetail.vue";

const module = "makercheckers";

export default defineComponent({
  name: "ShowMakerChecker",

  props: {},

  setup(props) {
    const store = useStore();
    const route = useRoute();

    const action = ref(null);
    const section = ref(null);
    const deserializedData = ref(null);
    const makerCheckerData = ref(null);

    // fetch id
    const maker_checker_id = route.params && route.params.id;

    fetchMakerChecker(maker_checker_id);

    async function fetchMakerChecker(maker_checker_id) {
      makerCheckerData.value = await store.dispatch(
        `${module}/fetchMakerChecker`,
        maker_checker_id
      );
    }

    // watch for changes in the section.value
    watch(makerCheckerData, (newMakerCheckerData) => {
      section.value = newMakerCheckerData.section;
      makerCheckerData.value = newMakerCheckerData;
    });

    // determine the component based on section
    const sectionComponent = computed(() => {
      if (section.value === "branch") {
        return BranchDetail;
      } else if (section.value === "paybill") {
        return PaybillDetail;
      } else {
        return BranchDetail;
      }
    });

    return {
      action,
      section,
      deserializedData,
      sectionComponent,
      makerChecker: computed(() => makerCheckerData.value),
      makerCheckerId: maker_checker_id,
    };
  },
});
</script>
