import axios from "axios";

export default {

    namespaced: true,

    state: {

        savings: [],
        saving: {},
        savingbalance: {},
        sharebalance: {},
        loanbalance: {}

    },

    getters: {

        savings(state) {
            return state.savings;
        },
        saving(state) {
            return state.saving;
        },
        savingbalance(state) {
            return state.savingbalance;
        },
        sharebalance(state) {
            return state.sharebalance;
        },
        loanbalance(state) {
            return state.loanbalance;
        }

    },

    mutations: {

        SET_SAVINGS(state, savings) {
            return state.savings = savings
        },

        SET_SAVING(state, saving) {
            return state.saving = saving
        },

        SET_SAVING_BALANCE(state, savingbalance) {
            return state.savingbalance = savingbalance
        },

        SET_SHARE_BALANCE(state, sharebalance) {
            return state.sharebalance = sharebalance
        },

        SET_LOAN_BALANCE(state, loanbalance) {
            return state.loanbalance = loanbalance
        }

    },

    actions: {

        async fetchCustomerSavings({ commit }, params) {

            await axios.get('/api/v1/savingsdata/customersavings', { params: params })
                .then(res => {
                    commit('SET_SAVINGS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchSavings({ commit }, params) {

            await axios.get('/api/v1/savingsdata', { params: params })
                .then(res => {
                    commit('SET_SAVINGS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchShareBalance({ commit }, params) {

            await axios.get('/api/v1/savingsdata/savingbalances', { params: params })
                .then(res => {
                    commit('SET_SHARE_BALANCE', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoanBalance({ commit }, params) {

            await axios.get('/api/v1/savingsdata/savingbalances', { params: params })
                .then(res => {
                    commit('SET_LOAN_BALANCE', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchSavingBalance({ commit }) {

            await axios.get('/api/v1/savingsdata/savingbalances')
                .then(res => {
                    commit('SET_SAVING_BALANCE', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchSaving({ commit }, saving_id) {

            await axios.get(`/api/v1/savingsdata/${saving_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_SAVING', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createSaving({ commit, dispatch }, saving) {

            await axios.post('/api/v1/savingsdata', saving)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchSavings');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateSaving({ commit, dispatch }, saving) {

            await axios("/api/v1/savingsdata/" + saving.customer_id, {
                method: "patch",
                data: saving,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchSavings');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteSaving({ commit }, saving_id) {

            await axios.delete(`/api/v1/savingsdata/${saving_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchSavings');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
